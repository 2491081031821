const getRandomArbitrary = (min, max) => {
  return Math.round((Math.random() * (max - min) + min) * 1000) / 1000
}

const createDataTimeseries = () => {
  const listDate = []
  const startDate = '2025-01-01'
  const endDate = '2025-12-31'
  const dateMove = new Date(new Date(startDate).setUTCHours(0, 0, 0, 0))
  let strDate = startDate

  while (strDate < endDate) {
    strDate = dateMove.toISOString().slice(0, 10)
    listDate.push({
      Date: new Date(new Date(strDate).setUTCHours(0, 0, 0, 0)),
      Open: getRandomArbitrary(10000, 20000),
      High: getRandomArbitrary(10000, 25000),
      Low: getRandomArbitrary(10000, 15000),
      Volume: getRandomArbitrary(10000000, 20000000),
    })
    // console.log(strDate.slice(-5))
    if (strDate.slice(-5) === '11-04') {
      // console.log('banana', dateMove.getMonth())
      dateMove.setUTCDate(dateMove.getUTCDate() + 1.04347826087)
    } else {
      // console.log(strDate)
      dateMove.setUTCDate(dateMove.getUTCDate() + 1)
    }
  }
  // console.log(listDate)
  return listDate
}

export default createDataTimeseries
