import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import debounce from "lodash/debounce";

const ChordDependency = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);

  const margin = { top: 90, right: 50, bottom: 50, left: 50 },
    width = props.width - margin.left - margin.right,
    height = props.width - margin.top - margin.bottom,
    innerRadius = Math.min(props.width) * 0.5 - 90,
    outerRadius = innerRadius + 10;

  useEffect(() => {
    let data = props.data.links;

    const names = Array.from(
      new Set(data.flatMap((d) => [d.source, d.target]))
    ).sort(d3.ascending);
    const svgEl = d3.select(svgRef.current);

    const index = new Map(names.map((name, i) => [name, i]));
    const matrix = Array.from(index, () => new Array(names.length).fill(0));
    for (const { source, target, count } of data)
      matrix[index.get(source)][index.get(target)] += count;
    const chord = d3
      .chordDirected()
      .padAngle(10 / innerRadius)
      .sortSubgroups(d3.descending)
      .sortChords(d3.descending);
    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
    const ribbon = d3
      .ribbonArrow()
      .radius(innerRadius - 1)
      .padAngle(1 / innerRadius);
    const color = d3.scaleOrdinal(
      names,
      d3.quantize(d3.interpolateRainbow, names.length)
    );

    svgEl.selectAll("*").remove();

    const svg = svgEl
      .append("g")
      .attr("transform", `translate(${props.width / 2},${props.width / 2})`)
      .attr("viewBox", `-${width / 2} ${width / 2}`)
      .style("display", "block");

    const chords = chord(matrix);

    const group = svg
      .append("g")
      .attr("font-size", 10)
      .attr("fill", "white")
      .attr("font-family", "sans-serif")
      .selectAll("g")
      .data(chords.groups)
      .join("g");

    group
      .append("path")
      .attr("fill", (d) => color(names[d.index]))
      .attr("d", arc);

    group
      .append("text")
      .each((d) => (d.angle = (d.startAngle + d.endAngle) / 2))
      .attr("dy", "0.35em")
      .attr(
        "transform",
        (d) => `
            rotate(${(d.angle * 180) / Math.PI - 90})
            translate(${outerRadius + 5})
            ${d.angle > Math.PI ? "rotate(180)" : ""}
          `
      )
      .attr("text-anchor", (d) => (d.angle > Math.PI ? "end" : null))
      .text((d) => names[d.index]);

    group.append("title").text(
      (d) => `${names[d.index]}
    ${d3.sum(
      chords,
      (c) => (c.source.index === d.index) * c.source.value
    )} outgoing →
    ${d3.sum(
      chords,
      (c) => (c.target.index === d.index) * c.source.value
    )} incoming ←`
    );

    svg
      .append("g")
      .attr("fill-opacity", 0.7)
      .selectAll("path")
      .data(chords)
      .join("path")
      // .style("mix-blend-mode", "multiply")
      .attr("fill", (d) => color(names[d.target.index]))
      .attr("d", ribbon)
      .append("title")
      .text(
        (d) =>
          `${names[d.source.index]} → ${names[d.target.index]} ${
            d.source.value
          }`
      );
  }, [props.data, props.height, props.width]);

  return (
    <div
      id="chartContainer"
      className={classes.circlePackContainer}
      ref={containerRef}
    >
      <div id="ChordDependency">
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g ref={svgRef} />
        </svg>
      </div>
    </div>
  );
};

export default ChordDependency;
