import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import { color } from "d3";

const CircularBarchart = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const margin = { top: 50, right: 50, bottom: 50, left: 50 },
    width = props.width - margin.left - margin.right,
    height = props.width - margin.top - margin.bottom,
    innerRadius = 90,
    outerRadius = Math.min(props.width, props.width) / 2;

  const cache = useRef(props.data);

  let data = props.data;
  useEffect(() => {
    const x = d3
      .scaleBand()
      .range([0, 2 * Math.PI]) // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
      .align(0) // This does nothing
      .domain(
        data.map(function (d) {
          return d.Country;
        })
      );
    const y = d3
      .scaleRadial()
      .range([innerRadius, outerRadius]) // Domain will be define later.
      .domain([0, 13000]);

    const ybis = d3
      .scaleRadial()
      .range([innerRadius, 5]) // Domain will be defined later.
      .domain([0, 13000]);

    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const svg = svgEl
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          "," +
          (height / 2 + margin.top) +
          ")"
      );

    svg
      .append("g")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("fill", "#69b3a2")
      .attr("class", "yo")
      .attr(
        "d",
        d3
          .arc() // imagine your doing a part of a donut plot
          .innerRadius(innerRadius)
          .outerRadius(function (d) {
            return y(d["Value"]);
          })
          .startAngle(function (d) {
            return x(d.Country);
          })
          .endAngle(function (d) {
            return x(d.Country) + x.bandwidth();
          })
          .padAngle(0.01)
          .padRadius(innerRadius)
      );

    svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("text-anchor", function (d) {
        return (x(d.Country) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) <
          Math.PI
          ? "end"
          : "start";
      })
      .attr("transform", function (d) {
        return (
          "rotate(" +
          (((x(d.Country) + x.bandwidth() / 2) * 180) / Math.PI - 90) +
          ")" +
          "translate(" +
          (y(d["Value"]) + 10) +
          ",0)"
        );
      })
      .append("text")
      .style("fill", "white")
      .text(function (d) {
        return d.Country;
      })

      .attr("transform", function (d) {
        return (x(d.Country) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) <
          Math.PI
          ? "rotate(180)"
          : "rotate(0)";
      })
      .style("font-size", "11px")

      .attr("alignment-baseline", "middle");

    svg
      .append("g")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("fill", "purple")
      .attr(
        "d",
        d3
          .arc() // imagine your doing a part of a donut plot
          .innerRadius(function (d) {
            return ybis(0);
          })
          .outerRadius(function (d) {
            return ybis(d["Value"]);
          })
          .startAngle(function (d) {
            return x(d.Country);
          })
          .endAngle(function (d) {
            return x(d.Country) + x.bandwidth();
          })
          .padAngle(0.01)
          .padRadius(innerRadius)
      );
  }, [data, props.width]);

  return (
    <div>
      <div
        className={classes.chartContainer}
        ref={containerRef}
        id="CircularBarchart"
      >
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>
    </div>
  );
};

export default CircularBarchart;
