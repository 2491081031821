import React, { useEffect, useState } from "react";
import Layout from "../common/Layout";
import { FaSistrix } from "react-icons/fa";
import TimeSeries from "../dashboard/TimeSeries";
import BarChart from "../dashboard/BarChart";
import Heatmap from "../dashboard/Heatmap";
import CircularBarchart from "../dashboard/CircularBarchart";
import CountryList from "../data/dashboard/CountryList.json";
// import data from "../data/dashboard/custom.json";
//import us from "../data/dashboard/states-albers-10m.json";
//import us from "../data/dashboard/world.json";
import us from "../data/dashboard/Bhutan.json";
import embassynetwork from "../data/dashboard/embassynetwork.json";
import posnetwork from "../data/dashboard/posnetwork.json";
import NetworkGraph3 from "../dashboard/NetworkGraph3";

import timedata from "../data/dashboard/sfhh@4.json";
import french_verbs from "../data/dashboard/french_verbs.json";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CirclePack from "../dashboard/CirclePack";

import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Voronoi from "../dashboard/Voronoi";
import TimeNetwork from "../dashboard/TimeNetwork";
import AITab from "../mycomponents/AITab";

import classes from "./TestPage.module.css";
import SkillsTab from "../elements/tab/SkillsTab";
import DevTab from "../elements/tab/DevTab";
import AccordionOne from "../mycomponents/AccordionOne";
import AccordionTwo from "../mycomponents/AccordionTwo";
import ProjectAccordion from "../mycomponents/ProjectAccordion";
import BreadcrumbOne from "../mycomponents/breadcrumb/BreadcrumbOne";
import SEO from "../common/SEO";

const PortfolioAI = () => {
  const Countries = CountryList[0].text.split(",");
  const Fruit = CountryList[0].fruit.split(",");
  const [height, setHeight] = useState(document.documentElement.clientHeight);
  const [width, setWidth] = useState(
    document.documentElement.clientWidth -
      Math.round(document.documentElement.clientWidth * 0.25)
  );
  useEffect(() => {}, []);

  const portfolio_ai = [
    {
      title: "ChilenoGPT",
      subtitle: "Efforts to teach GPT to speak Chilean Spanish",
      text: [
        "<p>Having lived in Chile for several years, I’m honored to have learned one of the most bizarre dialects of Spanish. Peppered with superfluous nonsense words and brimming with creative slang, Chilean Spanish is notably distinct in terms of its diction and style. Given the limited resources for Chilean Spanish, I scraped the entirety of Chile-themed subreddits in order to finetune a GPT model that seems to reliably imitate many of the idiosyncrasies of Chilean Spanish. As LLMs become more ubiquitous, it’s essential that they begin to incorporate some not-so-standard dialects of a given language.</p>",
      ],
      button: "SEE CODE",
      link: "github-chileno",
    },
    {
      title: "ChatGPT for EdTech",
      subtitle:
        "Using ChatGPT and BERT to generate custom homework assignments",
      text: [
        "<p>Building on my previous edtech projects, I used the ChatGPT API combined with a BERT ‘alternatives’ generator to create a module that generates fill-in-the-blank essays on any topic. I personally use this tool in my ESL classes in order to generate homework for my students based on their particular interests. </p>",
      ],
      button: "SEE CODE",
      link: "github-chatgpt",
    },
    {
      title: "Ritmo: The Rhyming Search Engine",
      subtitle:
        "a Spanish-language rhyming search engine built using ML/DL techniques",
      text: [
        "<p>Tokenization paradigms don't necessarily take into account the actual phonetics of a word. As such, I've created a rhyming search engine using various ML and DL methodologies.</p><p>There are two versions of the search engine: one that relies on Word2Vec and another that uses a finetuned BERT model. This rhyming search engine is particularly useful for data augmentation pipelines for ASR. And although this version is in Spanish, the same methods can easily be applied to other languages.</p>",
      ],
      button: "SEE CODE",
      link: "github-ritmo",
    },
    {
      title: "BERT's CLS Vectors for Rhetorical Analysis",
      subtitle:
        "Even off-the-shelf BERT creates rich encodings of text that allow for complex classification tasks.",
      text: [
        "<p>This project was part of a larger endeavor to explore the dynamics of embassy Twitter accounts. Though BERT is often more suited to short blocks of text, the versatile CLS vectors allow for the aggregation of these blocks into document embeddings that can create a vector representation of an entire Twitter stream. This provides a rough approximation of where a given account exists in relative vector space.</p><p>Moreover, when flattening the embeddings into two dimensions, it's quite evident that the accounts of both Russian and American embassies are readily distinguishable with some overlap where the rhetoric of the two countries doesn't differ significantly.</p>",
      ],
      button: "SEE CODE",
      link: "github-bert-document",
    },
    {
      title: "BERT for EdTech",
      subtitle:
        "Having been a teacher, sometimes it's quite challenging to come up with 'decoy' answers for multiple choice questions. BERT can be used to speed up the process.",
      text: "<p>As a teacher trying to make bespoke tests for my students, coming up with 'alternatives' for multiple choice questions can be surprisingly time-consuming. Off-the-shelf BERT does a great job at streamlining this task.</p><p>This simple script allows users to input a sentence and an answer to create a multiple-choice question. I use this myself when making exams and it certainly speeds up the process.</p>",
      button: "SEE CODE",
      link: "github-bert-ed",
    },
    {
      title: "SimpsonsGPT",
      text: [
        "<p>This GPT model was finetuned on script summaries from The Simpsons. With a starter prompt, the model generates an episode of the Simpsons.</p><p>GPT isn't intelligent, but it's great at learning idiosyncratic language patterns.</p><p>Check out some of the code and outputs. A lot of the episodes are pretty funny, even if they don't always make sense.</p>",
      ],
      button: "CODE",
      subtitle: "Using GPT to generating episodes of the Simpsons",
      link: "github-simpsons",
    },
    {
      title: "EconomistGPT",
      subtitle: "Using GPT to generate fake 'The Economist' articles",
      text: [
        "<p>This finetuned GPT model generates world news articles in the dour precision of an Etonian Thatcherite.</p><p>This project was a great way to explore GPTs' capacity for creating misinformation content. </p><p>Check out the code and try making your own articles.</p>",
      ],
      button: "SEE CODE",
      link: "github-economist",
    },
    {
      title: "Keyword-to-Text with T5",
      text: [
        "<p>This project demonstrates the capacity of encoder-decoder models like T5 to generate text based off a single input keyword.</p><p>The purpose of a model like this is to generate sentences for ed-tech applications wherein the input keyword is used in a proper context.</p>",
      ],
      button: "CODE",
      subtitle:
        "Using T5 to 'unsummarize' keywords to generate content for ed-tech applications.",
      link: "github-keyword",
    },
    {
      title: "Dzongkha Romanization with LLMs",
      subtitle:
        "Romanization is often an important intermediate step for NLP and ASR pipelines that involve multiple languages.",
      text: [
        "<p>The Dzongkha language is notoriously unphonetic. I undertook this project in the hope of being able to consistently romanize Dzongkha text so that a Dzongkha-learner like myself would be able to get a better sense of the pronunciation. Unfortunately, due to my very small romanized Dzongkha data set and the fact that LLMs are pretrained on very different language patterns, my initial attempts to romanize Dzongkha are largely failures.</p><p>Despite my issues with romanizing Dzongkha, I was able to create a proof of concept with a much more robust romanized Thai dataset. As such, hopefully I'll be able to replicate the process with Dzongkha one day.</p><p>If anybody knows Dzongkha and would like to help me with this project, feel free to contact me ;)</p>",
      ],
      button: "SEE CODE",
      link: "github-t5-dzongkha",
    },
  ];

  return (
    <Layout>
      <SEO title="Deep Learning and AI" />
      <BreadcrumbOne
        title="AI Portfolio"
        rootUrl="/"
        parentUrl="Home"
        currentUrl="Advance Tab"
      />
      <div>
        <div className="container">
          <div>
            <div className="row mb--40 mt--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Transformers"
                  title="NLP for Artificial Intelligence"
                  description="The multi-headed attention of transformer-based models such as BERT, T5, and GPT has indeed transformed the power and potential of NLP for AI. Check out some of my sample projects below. I'm always adding more projects, so check back for updates."
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="row mb--40">
                <div className="col-lg-12">
                  <ProjectAccordion data={portfolio_ai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PortfolioAI;
