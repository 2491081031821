import React from 'react'
import { Link } from 'react-router-dom'

const TabPaneler = (props) => {
  return (
    <div className='rn-tab-content'>
      <div className='inner'>
        <div className='row align-items-center row--30'>
          <div className='col-lg-4'>
            <div className='section-title'>
              <h4 className='title'>{props.title}</h4>
              <p>{props.description}</p>
            </div>
          </div>
          <div className='col-lg-8 mt_md--30 mt_sm--30'>
            <div className='row row--15 mt_dec--30 service-wrapper'>
              {props.dataList.map((val, i) => (
                <div className='col-lg-6 col-md-6 col-sm-6 col-12' key={i}>
                  <div className='card-box card-style-1 text-start'>
                    <div className='inner'>
                      <div className='image'>
                        <Link to='#service'>
                          {/* <img
                                              src={`${val.image}`}
                                              alt="card Images"
                                            /> */}
                        </Link>
                      </div>
                      <div className='content'>
                        <h4 className='title mb--20'>
                          <Link
                            to='#service'
                            dangerouslySetInnerHTML={{
                              __html: val.title,
                            }}
                          ></Link>
                        </h4>
                        <p
                          className='description b1 color-gray mb--0'
                          dangerouslySetInnerHTML={{
                            __html: val.description,
                          }}
                        ></p>
                        {props.codeButton ? (
                          <Link className='btn-default btn-small' to={val.link}>
                            Code
                          </Link>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabPaneler
