import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";

const NetworkGraph = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [nodesUnstick, setNodesUnstick] = useState(true);

  const margin = { top: 10, right: 10, bottom: 10, left: 10 },
    width = props.width - margin.left - margin.right,
    height =
      props.width > 1000 ? 700 : props.width - margin.top - margin.bottom;

  const unstickNodes = () => {
    setNodesUnstick(!nodesUnstick);
  };

  useEffect(() => {
    let data = props.data;
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const nodes = data.nodes;
    const links = data.links;

    const nodeGroup = (d) => d.group;
    let nodeTitle = (d) => d.id;
    const linkStrokeWidth = 1.0;
    // const linkStrokeWidth = (l) => Math.sqrt(l.value)
    const nodeId = (d) => d.id;
    const nodeFill = "green";
    const nodeStroke = "#fff";
    let nodeGroups;
    let invalidation;
    const radiusRange = (d) => {
      return d === "pos" ? 5 : 15;
    };
    const textRange = (d) => {
      return d === "pos" ? `10px georgia sans-serif` : `1px georgia sans-serif`;
    };
    const nodeStrokeWidth = 1.5; // node stroke width, in pixels
    const nodeStrokeOpacity = 1; // node stroke opacity
    const nodeRadius = 5; // node radius, in pixels
    let nodeStrength;
    const linkSource = ({ source }) => source; // given d in links, returns a node identifier string
    const linkTarget = ({ target }) => target; // given d in links, returns a node identifier string
    const linkStroke = "#999"; // link stroke color
    const linkStrokeOpacity = 0.3; // link stroke opacity
    const linkStrokeLinecap = "round"; // link stroke linecap
    let linkStrength = 1;
    const colors = d3.schemeTableau10;
    // const width = 640; // outer width, in pixels
    // const height = 400;

    const N = d3.map(nodes, nodeId).map(intern);
    const LS = d3.map(links, linkSource).map(intern);
    const LT = d3.map(links, linkTarget).map(intern);
    // if (nodeTitle === undefined) nodeTitle = (_, i) => N[i];
    const T = nodeTitle == null ? null : d3.map(nodes, nodeTitle).map(intern);
    const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
    const W =
      typeof linkStrokeWidth !== "function"
        ? null
        : d3.map(links, linkStrokeWidth);
    const L =
      typeof linkStroke !== "function" ? null : d3.map(links, linkStroke);

    // Replace the input nodes and links with mutable objects for the simulation.
    const nodes_ = d3.map(data.nodes, (_, i) => ({ id: N[i] }));
    const links_ = d3.map(data.links, (_, i) => ({
      source: LS[i],
      target: LT[i],
    }));

    console.log(colors);

    // Compute default domains.
    if (G && nodeGroups === undefined) nodeGroups = d3.sort(G);
    console.log(G);
    // Construct the scales.
    const color =
      nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

    // Construct the forces.
    const forceNode = d3.forceManyBody().strength(-width / 5);
    // const forceTitle = d3.forceManyBody();
    const forceLink = d3.forceLink(links_).id(({ index: i }) => N[i]);
    if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
    // if (titleStrength !== undefined) forceTitle.strength(titleStrength);
    if (linkStrength !== undefined) forceLink.strength(linkStrength);

    const simulation = d3
      .forceSimulation(nodes_)
      .force("link", forceLink)
      .force("charge", forceNode)
      .force("x", d3.forceX())
      .force("y", d3.forceY())
      //   .force("center", d3.forceCenter(width / 2, height / 2))
      .on("tick", tick);

    const svg = svgEl
      .append("svg")
      // .attr("width", width)
      // .attr("height", height)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    const link = svg
      .append("g")
      .attr("stroke", typeof linkStroke !== "function" ? linkStroke : null)
      .attr("stroke-opacity", linkStrokeOpacity)
      .attr(
        "stroke-width",
        typeof linkStrokeWidth !== "function" ? linkStrokeWidth : null
      )
      .attr("stroke-linecap", linkStrokeLinecap)
      .selectAll("line")
      .data(links_)
      .classed("link", true)
      .join("line");

    const node = svg
      .append("g")
      .attr("fill", nodeFill)
      //   .attr("stroke", nodeStroke)
      .attr("stroke-opacity", nodeStrokeOpacity)
      .attr("stroke-width", nodeStrokeWidth)
      //   .style("stroke", (d) => (d.fx !== undefined ? "red" : "transparent"))
      .selectAll("circle")
      .data(nodes_)
      .join("circle")
      .attr("r", nodeRadius)
      .classed("node", true)
      .classed("fixed", (d) => d.fx !== undefined);

    const text = svg
      .append("g")
      .attr("class", "text")
      .selectAll("text")
      .data(nodes_)
      .enter()
      .append("text")
      .style("font", `10px georgia sans-serif`)
      .style("fill", "white")
      .style("font-weight", function (d, i) {
        return i * 100 + 500;
      })
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .text((d) => d.id);

    if (W) link.attr("stroke-width", ({ index: i }) => W[i]);
    if (L) link.attr("stroke", ({ index: i }) => L[i]);
    if (G)
      node
        .attr("fill", ({ index: i }) => color(G[i]))
        .attr("r", ({ index: i }) => radiusRange(G[i]));

    if (G) text.attr("font-size", ({ index: i }) => textRange(G[i]));
    if (T) node.append("title").text(({ index: i }) => T[i]);
    if (invalidation != null) invalidation.then(() => simulation.stop());

    //// functions
    function intern(value) {
      return value !== null && typeof value === "object"
        ? value.valueOf()
        : value;
    }

    function clamp(x, lo, hi) {
      return x < lo ? lo : x > hi ? hi : x;
    }

    const drag = d3.drag().on("start", dragstart).on("drag", dragged);

    node.call(drag).on("click", click);

    function tick() {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);
      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      text
        .attr("x", (d) => d.x - 15) //position of the lower left point of the text
        .attr("y", (d) => d.y + 15); //position of the lower left point of the text
    }

    function click(event, d) {
      console.log("clicked");
      delete d.fx;
      delete d.fy;
      d3.select(this).attr("stroke", "transparent");
      d3.select(this).classed("fixed", false);
      simulation.alpha(1).restart();
    }

    function dragstart() {
      console.log("dragstart");
      d3.select(this).classed("fixed", true);
    }

    function dragged(event, d) {
      console.log("dragged");
      d.fx = event.x; //clamp(event.x, event.x, width);
      console.log("dragstart");
      d.fy = event.y; //clamp(event.y, event.y, height);
      d3.select(this).attr("stroke", "pink");
      d3.select(this).attr("stroke-width", nodeStrokeWidth * 2);
      simulation.alpha(1).restart();
    }
  }, [props.data, props.height, props.width, nodesUnstick]);

  return (
    <div
      id="chartContainer"
      className={classes.networkContainer}
      ref={containerRef}
    >
      <div id="NetworkGraph">
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>
      <div className="row ml--10 mr--10 mb--5 mt--10">
        <button
          className="btn-default btn-small mr--10 ml--10"
          onClick={unstickNodes}
        >
          Unstick Nodes
        </button>
        {/* <Link className="btn-default btn-small mr--10 ml--10" to={`/`}>
          See Code
        </Link> */}
      </div>
    </div>
  );
};

export default NetworkGraph;
