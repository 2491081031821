import React, { useEffect, useState } from "react";
import Layout from "../common/Layout";
import { FaSistrix } from "react-icons/fa";
import TimeSeries from "../dashboard/TimeSeries";
import BarChart from "../dashboard/BarChart";
import Heatmap from "../dashboard/Heatmap";
import CircularBarchart from "../dashboard/CircularBarchart";
import CountryList from "../data/dashboard/CountryList.json";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Treemap from "../dashboard/Treemap";
import ChoroplethMap from "../dashboard/ChoroplethMap";

const Dashboard = () => {
  const Countries = CountryList[0].text.split(",");
  const Fruit = CountryList[0].fruit.split(",");
  const [height, setHeight] = useState(document.documentElement.clientHeight);
  const [width, setWidth] = useState(
    document.documentElement.clientWidth -
      Math.round(document.documentElement.clientWidth * 0.25)
  );
  const [dummyBarChart, setDummyBarChart] = useState([]);
  const [dummyHeatChart, setDummyHeatChart] = useState([]);
  const [dummySeriesChart, setDummySeriesChart] = useState([]);
  const [dummyCircularChart, setDummyCircularChart] = useState([]);
  const [dummyTreemapChart, setDummyTreemapChart] = useState([]);

  const createDummyBarChartData = () => {
    const ARRAY_LENGTH = 10;
    const randomArray = [];
    for (let i = 0; i < ARRAY_LENGTH; i++) {
      randomArray.push(Math.random());
    }
    const listArray = Array.from(Array(10).keys());

    function func(arr1, arr2) {
      const obj = [];
      arr1.forEach((Curr_element, index) => {
        obj[index] = { x: arr1[index], y: arr2[index] };
      });
      return obj;
    }
    const jsondum = func(listArray, randomArray);
    setDummyBarChart(jsondum);
  };

  const createDummyHeatChartData = () => {
    const shuffled = Countries.sort(() => 0.5 - Math.random());
    let categories = shuffled.slice(0, 10);
    const ARRAY_LENGTH = 50;
    const repeated = new Array(5).fill(Array.from(Array(10).keys())).flat();
    const randomArray = [];
    for (let i = 0; i < ARRAY_LENGTH; i++) {
      randomArray.push(Math.random() * 10);
    }

    function func(arr1, arr2) {
      const obj = [];
      arr1.forEach((Curr_element, index) => {
        obj[index] = {
          x: arr1[index],
          y: categories[Math.floor(Math.random() * categories.length)],
          color: arr2[index],
        };
      });
      return obj;
    }
    const jsondum = func(repeated, randomArray);
    setDummyHeatChart(jsondum);
  };

  const createDummySeriesChartData = () => {
    const shuffled = Countries.sort(() => 0.5 - Math.random());
    let categories = shuffled.slice(0, 10);

    const randomNumber = (min, max) => {
      return Math.random() * (max - min) + min;
    };
    const ARRAY_LENGTH = 60;
    const repeated = Array.from(Array(ARRAY_LENGTH).keys());
    const randomArray = [];
    for (let i = 0; i < ARRAY_LENGTH; i++) {
      randomArray.push(randomNumber(100, 200));
    }
    function func(arr1, arr2) {
      const obj = [];
      arr1.forEach((Curr_element, index) => {
        obj[index] = {
          x: { day: arr1[index] },
          color: categories[Math.floor(Math.random() * categories.length)],
          y: arr2[index],
        };
      });
      return obj;
    }
    const jsondum = func(repeated, randomArray);
    setDummySeriesChart(jsondum);
  };

  const createDummyCircularChartData = () => {
    const shuffled = Countries.sort(() => 0.5 - Math.random());
    let categories = shuffled.slice(0, 40);
    const ARRAY_LENGTH = 40;

    const randomArray = [];
    for (let i = 0; i < ARRAY_LENGTH; i++) {
      randomArray.push(Math.random() * 4000);
    }
    function func(arr1, arr2) {
      const obj = [];
      arr1.forEach((Curr_element, index) => {
        obj[index] = { Country: arr1[index], Value: arr2[index] };
      });
      return obj;
    }
    const jsondum = func(categories, randomArray);
    setDummyCircularChart(jsondum);
  };

  const createDummyTreemapChartData = () => {
    const shuffled = Countries.sort(() => 0.5 - Math.random());
    let categories = shuffled.slice(0, 4);
    const shuffled2 = Fruit.sort(() => 0.5 - Math.random());
    let categories2 = shuffled2.slice(0, 3);
    const ARRAY_LENGTH = 40;
    const repeated = Array.from(Array(ARRAY_LENGTH).keys());
    const randomArray = [];
    for (let i = 0; i < ARRAY_LENGTH; i++) {
      randomArray.push(Math.random() * 10);
    }
    function func(arr1, arr2) {
      const obj = [];
      arr1.forEach((Curr_element, index) => {
        obj[index] = {
          name: arr2[Math.floor(Math.random() * arr2.length)],
          group: arr2[Math.floor(Math.random() * arr2.length)],
          value: Math.round(Math.random() * 200, 2),
          colname: "level3",
        };
      });
      return obj;
    }

    const data = [];
    categories.forEach((country, index) => {
      data[index] = {
        name: country,
        children: func(categories2, categories2),
        colname: "level2",
      };
    });
    const dummyData = [{ children: data, name: "World" }];
    setDummyTreemapChart(dummyData);
  };

  const randomData = () => {
    createDummyBarChartData();
    createDummyHeatChartData();
    createDummySeriesChartData();
    createDummyCircularChartData();
    createDummyTreemapChartData();
  };

  const eventListener = () => {
    if (document.getElementById("chartContainer3").clientWidth != null) {
      setWidth(document.getElementById("chartContainer3").clientWidth);
      setHeight(document.getElementById("chartContainer3").clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", eventListener);
    return () => {
      window.removeEventListener("resize", eventListener);
    };
  }, [width]);

  useEffect(() => {
    createDummyBarChartData();
    createDummyHeatChartData();
    createDummySeriesChartData();
    createDummyCircularChartData();
    createDummyTreemapChartData();
  }, []);

  return (
    <Layout>
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <div className="row row--30 align-items-center">
                    {/* <div className="col-lg-5">
                <img
                  className="radius-small"
                  src="./images/tab/tabs-01.jpg"
                  alt="Corporate React Template"
                />
              </div> */}
                    <div className="col-lg-12 mt_md--40 mt_sm--40">
                      <div className="rn-default-tab">
                        <div className="tab-button-panel">
                          <TabList className="tab-button">
                            <Tab>
                              <div className="rn-tab-button">
                                <button>Time Series</button>
                              </div>
                            </Tab>
                            <Tab>
                              <div className="rn-tab-button">
                                <button>Bar Chart</button>
                              </div>
                            </Tab>
                            <Tab>
                              <div className="rn-tab-button">
                                <button>Heatmap</button>
                              </div>
                            </Tab>
                            <Tab>
                              <div className="rn-tab-button">
                                <button>Circular Bar Chart</button>
                              </div>
                            </Tab>
                            <Tab>
                              <div className="rn-tab-button">
                                <button>Treemap</button>
                              </div>
                            </Tab>
                            {/* <Tab>
                              <div className="rn-tab-button">
                                <button>Choropleth Map</button>
                              </div>
                            </Tab> */}
                          </TabList>
                        </div>

                        <div className="tab-content-panel">
                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <div id="chartContainer3">
                                  <TimeSeries
                                    height={height}
                                    width={width}
                                    data={dummySeriesChart}
                                  />
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <div>
                                  <BarChart
                                    height={height}
                                    width={width}
                                    data={dummyBarChart}
                                  />
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <div>
                                  <Heatmap
                                    height={height}
                                    width={width}
                                    data={dummyHeatChart}
                                  />
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <div>
                                  <CircularBarchart
                                    height={height}
                                    width={width}
                                    data={dummyCircularChart}
                                  />
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <div>
                                  <Treemap
                                    height={height}
                                    width={width}
                                    data={dummyTreemapChart}
                                  />
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          {/* <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <div>
                                  <ChoroplethMap
                                    height={height}
                                    width={width}
                                  />
                                </div>
                              </div>
                            </div>
                          </TabPanel> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs>
              </div>
              <div className="row align-item-center">
                <div className="col-lg-12">
                  <div className="error-inner">
                    <div className="view-more-button">
                      <a className="btn-default" onClick={randomData}>
                        Randomize Data
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
