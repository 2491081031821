import React, { useEffect, useState } from 'react'
import Layout from '../common/Layout'
import { FaSistrix } from 'react-icons/fa'
import CountryList from '../data/dashboard/CountryList.json'

import BreadcrumbOne from '../mycomponents/breadcrumb/BreadcrumbOne'
import DashboardAccordion from '../mycomponents/DashboardAccordion'
import createDummyBarChartData from '../datafunctions/createDummyBarchartData'
import BarChart from '../dashboard/BarChart'

const PortfolioDash = () => {
  const Countries = CountryList[0].text.split(',')
  const Fruit = CountryList[0].fruit.split(',')
  const [height, setHeight] = useState(document.documentElement.clientHeight)
  const [width, setWidth] = useState(
    document.documentElement.clientWidth -
      Math.round(document.documentElement.clientWidth * 0.25)
  )
  useEffect(() => {}, [width])

  const eventListener = () => {
    if (document.getElementById('dashboardAccordion').clientWidth != null) {
      setWidth(document.getElementById('dashboardAccordion').clientWidth)
      setHeight(document.getElementById('dashboardAccordion').clientHeight)
    }
    console.log(width)
  }

  const [dummyBarChart, setDummyBarChart] = useState([])

  const randomData = () => {
    setDummyBarChart(createDummyBarChartData())
  }
  useEffect(() => {
    window.addEventListener('resize', eventListener)
    randomData()
    return () => {
      window.removeEventListener('resize', eventListener)
    }
  }, [width])

  const dashboards = [
    {
      title: 'Education Dashboard',
      subtitle:
        'In business computer information systems, a dashboard is a type of graphical user interface',
      text: 'In business computer information systems, a dashboard is a type of graphical user interface which often provides at-a-glance views of key performance indicators (KPIs) relevant to a particular objective or business process. In other usage, "dashboard" is another name for "progress report" or "report" and considered a form of data visualization. In providing this overview, business owners can save time and improve their decision making by utilizing dashboards.',
      button: 'CODE!',
      link: '/dashboard-econ',
      minigraph: [<BarChart height={200} width={200} data={dummyBarChart} />],
    },
    {
      title: 'Business Dashboard',
      subtitle:
        'In business computer information systems, a dashboard is a type of graphical user interface',
      text: 'In business computer information systems, a dashboard is a type of graphical user interface which often provides at-a-glance views of key performance indicators (KPIs) relevant to a particular objective or business process. In other usage, "dashboard" is another name for "progress report" or "report" and considered a form of data visualization. In providing this overview, business owners can save time and improve their decision making by utilizing dashboards.',
      button: 'CODE!',
      link: '/dashboard-biz',
    },
    {
      title: 'Economics Dashboard',
      subtitle:
        'In business computer information systems, a dashboard is a type of graphical user interface',
      text: 'In business computer information systems, a dashboard is a type of graphical user interface which often provides at-a-glance views of key performance indicators (KPIs) relevant to a particular objective or business process. In other usage, "dashboard" is another name for "progress report" or "report" and considered a form of data visualization. In providing this overview, business owners can save time and improve their decision making by utilizing dashboards.',
      button: 'CODE!',
      link: '/dashboard-econ',
    },
    {
      title: 'Science Dashboard',
      subtitle:
        'In business computer information systems, a dashboard is a type of graphical user interface',
      text: 'In business computer information systems, a dashboard is a type of graphical user interface which often provides at-a-glance views of key performance indicators (KPIs) relevant to a particular objective or business process. In other usage, "dashboard" is another name for "progress report" or "report" and considered a form of data visualization. In providing this overview, business owners can save time and improve their decision making by utilizing dashboards.',
      button: 'CODE!',
      link: '/dashboard-econ',
    },
    {
      title: 'Health Crisis Dashboard',
      subtitle:
        'In business computer information systems, a dashboard is a type of graphical user interface',
      text: 'In business computer information systems, a dashboard is a type of graphical user interface which often provides at-a-glance views of key performance indicators (KPIs) relevant to a particular objective or business process. In other usage, "dashboard" is another name for "progress report" or "report" and considered a form of data visualization. In providing this overview, business owners can save time and improve their decision making by utilizing dashboards.',
      button: 'CODE!',
      link: '/dashboard-econ',
    },
    {
      title: 'Geo Dashboard',
      subtitle:
        'In business computer information systems, a dashboard is a type of graphical user interface',
      text: 'In business computer information systems, a dashboard is a type of graphical user interface which often provides at-a-glance views of key performance indicators (KPIs) relevant to a particular objective or business process. In other usage, "dashboard" is another name for "progress report" or "report" and considered a form of data visualization. In providing this overview, business owners can save time and improve their decision making by utilizing dashboards.',
      button: 'CODE!',
      link: '/dashboard-econ',
    },
  ]

  return (
    <Layout>
      {/* <div className='slider-area slider-style-6 shape-riht height-750'>
        <div className='container'>
          <div className='row row--30 align-items-center'>
            <div className='order-1 order-lg-2 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30'>
              <div className={classes.container}>
                <h1 className='title color-white'>Data Scientist</h1>
                <p className='description color-white'>
                  Looking for a data scientist or NLP engineer?
                </p>
              </div>
            </div>

            <div className='col-lg-6 order-2 order-lg-1'>
              <div className='row'>
                <TimeNetwork height={height} width={width} data={timedata} />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <BreadcrumbOne
        title='Dashboards / Viz'
        rootUrl='/'
        parentUrl='Home'
        currentUrl='Advance Tab'
      />
      <div>
        <div className='container'>
          {/* <div>
            <div className='row mb--40 mt--40 '>
              <div className='col-lg-12 '>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Hire Me'
                  title='Finding Patterns'
                  description='NLP engineers use algorithms to break down language into its constituent parts.'
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  <DashboardTabOne height={height} width={width} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='row mb--40 mt--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Hire Me'
                  title='Exploring Vector Space'
                  description='NLP engineers use statistics to explore the internal relationships between utterances.'
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='row'>
                <DashboardTabTwo height={height} width={width} />
              </div>
            </div>
          </div> */}
          <div>
            {/* <div className='row mb--40 mt--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Data Visualization'
                  title=''
                  description='Beautiful graphics for interfacing with knowledge.'
                />
              </div>
            </div> */}

            <div className='col-lg-12' id={'dashboardAccordion'}>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  <DashboardAccordion
                    data={dashboards}
                    width={width}
                    height={height}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PortfolioDash
