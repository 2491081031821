import React, { useEffect, useState } from 'react'
import Layout from '../common/Layout'
import { FaSistrix } from 'react-icons/fa'
import TimeSeries from '../dashboard/TimeSeries'
import BarChart from '../dashboard/BarChart'
import Heatmap from '../dashboard/Heatmap'
import CircularBarchart from '../dashboard/CircularBarchart'
import CountryList from '../data/dashboard/CountryList.json'
// import data from "../data/dashboard/custom.json";
//import us from "../data/dashboard/states-albers-10m.json";
//import us from "../data/dashboard/world.json";
import us from '../data/dashboard/Bhutan.json'
import embassynetwork from '../data/dashboard/embassynetwork.json'
import posnetwork from '../data/dashboard/posnetwork.json'
import NetworkGraph3 from '../dashboard/NetworkGraph3'

import timedata from '../data/dashboard/sfhh@4.json'
import french_verbs from '../data/dashboard/french_verbs.json'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import CirclePack from '../dashboard/CirclePack'

import SectionTitle from '../elements/sectionTitle/SectionTitle'
import Voronoi from '../dashboard/Voronoi'
import TimeNetwork from '../dashboard/TimeNetwork'
import AITab from '../elements/tab/AITab'
// import DashboardTabOne from './DashboardTabOne'
// import DashboardTabTwo from './DashboardTabTwo'
import classes from './TestPage.module.css'
import SkillsTab from '../elements/tab/SkillsTab'
import DevTab from '../elements/tab/DevTab'

const TestPage = () => {
  const Countries = CountryList[0].text.split(',')
  const Fruit = CountryList[0].fruit.split(',')
  const [height, setHeight] = useState(document.documentElement.clientHeight)
  const [width, setWidth] = useState(
    document.documentElement.clientWidth -
      Math.round(document.documentElement.clientWidth * 0.25)
  )
  useEffect(() => {}, [])

  return (
    <Layout>
      <div className='slider-area slider-style-6 shape-riht height-750'>
        <div className='container'>
          <div className='row row--30 align-items-center'>
            <div className='order-1 order-lg-2 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30'>
              <div className={classes.container}>
                <h1 className='title color-white'>Data Scientist</h1>
                <p className='description color-white'>
                  Looking for a data scientist or NLP engineer?
                </p>
              </div>
            </div>

            <div className='col-lg-6 order-2 order-lg-1'>
              <div className='row'>
                <TimeNetwork height={height} width={width} data={timedata} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='container'>
          <div>
            <div className='row mb--40 mt--40 '>
              <div className='col-lg-12 '>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Hire Me'
                  title='Finding Patterns'
                  description='NLP engineers use algorithms to break down language into its constituent parts.'
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  {/* <DashboardTabOne height={height} width={width} /> */}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='row mb--40 mt--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Hire Me'
                  title='Exploring Vector Space'
                  description='NLP engineers use statistics to explore the internal relationships between utterances.'
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='row'>
                {/* <DashboardTabTwo height={height} width={width} /> */}
              </div>
            </div>
          </div>
          <div>
            <div className='row mb--40 mt--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Hire Me'
                  title='Machine Learning and AI'
                  description='NLP engineers use deep learning techniques for natural language understanding and generation.'
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  <AITab />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='row mb--40 mt--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Hire Me'
                  title='Frontend Data Flows'
                  description='Bringing data to life in dashboards and visualizations.'
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  <DevTab />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='row mb--40 mt--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Hire Me'
                  title='The Brass Tacks'
                  description="Check out the lists below. Maybe I'm the person you're looking for."
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  <SkillsTab />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TestPage
