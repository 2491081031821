import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderOne from "../common/header/HeaderOne";
import HeaderTopBar from "../common/header/HeaderTopBar";

import Copyright from "../common/footer/Copyright";
import ScrollTop from "../common/footer/ScrollTop";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import BrandThree from "../elements/brand/BrandThree";

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

const CreativePortfolio = () => {
  return (
    <>
      <SEO title="Photography" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider area  */}
        {/* <div
          className='slider-area slider-style-2 variation-2  height-850 bg_image'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/myphotos/bros.png)`,
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner text-center'>
                  <h1 className='title'>Photography</h1>
                  <h4 class='subtitle rn-sub-badge'>
                    <span class='theme-gradient'>
                      A knack for finding myself in the world's corners
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Slider area  */}
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-6 shape-right height-750">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="title color-white">Photography</h1>
                  <h4 class="subtitle rn-sub-badge">
                    <span class="theme-gradient">
                      A knack for finding interesting corners
                    </span>
                  </h4>
                </div>
              </div>

              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail">
                  <img
                    src="./myphotos/bros2.png"
                    alt="Banner Images"
                    loading="lazy"
                  />
                </div>
              </div>
              {/* <div className='shape-image'>
                <img
                  src='./images/banner/white-shape.svg'
                  alt='Banner Images'
                />
              </div> */}
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        <div className="rwt-portfolio-area rn-section-gapTop">
          <div className="container-fluid plr--60 plr_md--15 plr_sm--15">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What is the world?"
                  title="Seeking Understanding"
                  description="I use photography to explore themes of postcolonialism, modernity, and globalization."
                />
              </div>
            </div>
            <PortfolioOne Column="col-lg-4 mt--30 box-grid-layout no-overlay" />
          </div>
        </div>

        <Copyright />
        <ScrollTop />
      </main>
    </>
  );
};
export default CreativePortfolio;
