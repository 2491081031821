import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// My Pages

import Photographer from "./pages/Photographer";
import Educator from "./pages/Educator";
import Technologist from "./pages/Technologist";
import AboutMe from "./pages/AboutMe";
import Dashboard from "./pages/Dashboard";
import DashboardCountry from "./pages/DashboardCountry";
import DashboardEd from "./pages/DashboardEd";
import DashboardEcon from "./pages/DashboardEcon";
import DashboardHealth from "./pages/DashboardHealth";
// Elements import Here

import PortfolioDetails from "./pages/PortfolioDetails";
import Contact from "./elements/contact/Contact";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";

// Import Css
import "./assets/scss/style.scss";
import NLPPage from "./pages/NLPPage";
import TestPage from "./pages/TestPage";
import DashboardTest from "./pages/DashboardTest";
import PortfolioNLP from "./pages/PortfolioNLP";
import PortfolioAI from "./pages/PortfolioAI";
import PortfolioDash from "./pages/PortfolioDash";
import Overview from "./pages/Overview";
import PortfolioMain from "./pages/PortfolioMain";
import AdvanceTab from "./pages/AdvanceTab";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={AboutMe}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/photography"}`}
            exact
            component={Photographer}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/educator"}`}
            exact
            component={Educator}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/dashboard"}`}
            exact
            component={Dashboard}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/technologist"}`}
            exact
            component={TestPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/test"}`}
            exact
            component={AdvanceTab}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            exact
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/dashboard-country"}`}
            exact
            component={DashboardCountry}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/dashboard-econ"}`}
            exact
            component={DashboardEcon}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/dashboard-ed"}`}
            exact
            component={DashboardEd}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/dashboard-health"}`}
            exact
            component={DashboardHealth}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/dashboard-test"}`}
            exact
            component={DashboardTest}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/technologist-overview"}`}
            exact
            component={Overview}
          />
          {/* <Route
            path={`${process.env.PUBLIC_URL + '/technologist-nlp'}`}
            exact
            component={PortfolioNLP}
          /> */}
          <Route
            path={`${process.env.PUBLIC_URL + "/technologist-nlp"}`}
            exact
            component={AdvanceTab}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/technologist-portfolio"}`}
            exact
            component={PortfolioMain}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/technologist-ai"}`}
            exact
            component={PortfolioAI}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/technologist-viz"}`}
            exact
            component={PortfolioDash}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
            exact
            component={BlogDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio-details/:id"}`}
            exact
            component={PortfolioDetails}
          />
          <Route
            path="/github-simpsons"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/SimpsonsGPT";
              return null;
            }}
          />
          <Route
            path="/github-economist"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/EconomistGPT";
              return null;
            }}
          />
          <Route
            path="/github-keyword"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/t5_keyword_to_text";
              return null;
            }}
          />
          <Route
            path="/github-t5-dzongkha"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/T5-for-dzongkha-romanization";
              return null;
            }}
          />
          <Route
            path="/github-chileno"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/ChilenoGPT";
              return null;
            }}
          />
          <Route
            path="/github-chatgpt"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/longform_edtech_BERT";
              return null;
            }}
          />
          <Route
            path="/github-bert-ed"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/BERT_edtech_tools";
              return null;
            }}
          />
          <Route
            path="/github-bert-document"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/nlp_social_science/blob/main/document_similarity_with_BERT.ipynb";
              return null;
            }}
          />
          <Route
            path="/github-bert-heatmap"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/data_prep_for_visualizations/blob/main/notebooks/BERT_vector_space.ipynb";
              return null;
            }}
          />
          <Route
            path="/github-literary"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/NLP_literary_analysis";
              return null;
            }}
          />
          <Route
            path="/github-orthographic"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/data_prep_for_visualizations/blob/main/notebooks/character_counts.ipynb";
              return null;
            }}
          />
          <Route
            path="/github-social-science"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/nlp_social_science";
              return null;
            }}
          />
          <Route
            path="/github-linguistic"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/linguistic_similarity_mapping";
              return null;
            }}
          />
          <Route
            path="/github-ritmo"
            component={() => {
              window.location.href =
                "https://github.com/s-cafferty-nlp/deep_ritmo";
              return null;
            }}
          />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
