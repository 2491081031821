import React from 'react'
import { Link } from 'react-router-dom'

const DashboardHeader = ({
  description,
  title,
  rootUrl,
  parentUrl,
  currentUrl,
}) => {
  return (
    <div className='breadcrumb-area breadcarumb-style-1 ptb--80'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='breadcrumb-inner text-center'>
              <h1
                className='title theme-gradient h2'
                dangerouslySetInnerHTML={{ __html: title }}
              ></h1>
              <ul className='page-list'>
                <li className='rn-breadcrumb-item'>
                  <Link
                    to={`${rootUrl}`}
                    dangerouslySetInnerHTML={{ __html: parentUrl }}
                  ></Link>
                </li>
                <li className='rn-breadcrumb-item active'>
                  <Link
                    to='/contact'
                    // to={`${rootUrl}`}
                    // dangerouslySetInnerHTML={{ __html: parentUrl }}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              <div className='col-lg-12 mt--20'>
                <div className='inner'>
                  <div>
                    <p className='description'>{description}</p>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader
