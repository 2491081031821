import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import debounce from "lodash/debounce";

const TimeSeries = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);

  const margin = { top: 90, right: 140, bottom: 50, left: 50 },
    width = props.width - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom,
    fontSize = props.width > 400 ? "10px" : "6px";

  useEffect(() => {
    let data = props.data;
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const svg = svgEl
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const sumstat = d3.group(data, (d) => d.color);

    const x = d3
      .scaleLinear()
      .domain(
        d3.extent(data, function (d) {
          return d.x.day;
        })
      )
      .range([0, width]);

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).ticks(5));

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain(
        d3.extent(data, function (d) {
          return d.y;
        })
      )
      .range([height, 0]);
    svg.append("g").call(d3.axisLeft(y));

    // color palette
    const res = Array.from(sumstat.keys());
    // list of group names
    const color = d3
      .scaleOrdinal()
      .domain(res)
      .range([
        "#377eb8",
        "#4daf4a",
        "#984ea3",
        "#ff7f00",
        "#ffff33",
        "#a65628",
        "#f781bf",
        "#999999",
      ]);

    // Draw the line
    svg
      .selectAll(".line")
      .data(sumstat)
      .join("path")
      .attr("fill", "none")
      .attr("stroke-width", 1.5)
      .attr("stroke", (d) => color(d[0]))
      .attr("d", (d) => {
        return d3
          .line()
          .curve(d3.curveMonotoneY)
          .x((d) => x(d.x.day))
          .y((d) => y(d.y))(d[1]);
      });

    const lineLegend = svg
      .selectAll(".lineLegend")
      .data(sumstat)
      .enter()
      .append("g")
      .attr("class", "lineLegend")
      .attr("transform", function (d, i) {
        return "translate(" + (width + 10) + "," + i * 20 + ")";
      });

    lineLegend
      .append("text")
      .attr("fill", "white")
      .attr("font-size", fontSize)
      .text(function (d) {
        return d[0];
      })
      .attr("transform", "translate(15,9)"); //align texts with boxes

    lineLegend
      .append("rect")
      .attr("fill", function (d, i) {
        return color(d[0]);
      })
      .attr("width", 10)
      .attr("height", 10);
  }, [props.data, props.height, props.width]);

  return (
    <div
      id="chartContainer"
      className={classes.chartContainer}
      ref={containerRef}
    >
      <div id="timeseries">
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>
    </div>
  );
};

export default TimeSeries;
