import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import TabPaneler from "./TabPaneler";

const dataScienceSkills = [
  "Python",
  "Javascript",
  "React.js - MERN stacks",
  "NLP",
  "Machine Learning",
  "Deep Learning",
  "AWS microservices",
  "Visualizations",
];

const otherSkills = [
  "Linguistics",
  "Academic Writing",
  "International Politics (M.A.)",
];

const futureDataSkills = [
  "Automated Speech Recognition",
  "React Native / Mobile Development",
  "Typescript",
  "Reinforcement Learning",
  "Kubernetes / Container Orchestration ",
];

const futureOtherSkills = [
  "Baking Croissants",
  "Brazilian jiu-jitsu",
  "The Sunday Crossword",
];

const aspirationalDataSkills = [
  "Automated Speech Recognition",
  "React Native / Mobile Development",
  "Typescript",
  "Reinforcement Learning",
  "Kubernetes / Container Orchestration ",
];

const aspirationalOtherSkills = [
  "Baking Croissants",
  "Jujitsu",
  "The Sunday Crossword",
];

const currentLanguages = ["English", "Spanish - C2", "French - C1"];

const futureLanguages = ["Portuguese", "Italian", "German", "Dutch"];

const aspirationalLanguages = [
  "Dzongkha",
  "Mandarin",
  "Japanese",
  "Korean",
  "Finnish",
];

const formatList = (dataList) => {
  return String(
    dataList.map((d) => {
      return `<li>${d}</li>`;
    })
  ).replaceAll(",", "");
};

const dataList = [
  {
    title: "Data Science",
    description: [formatList(dataScienceSkills)],
  },
  {
    title: "Other Skills",
    description: [formatList(otherSkills)],
  },
];

const dataList2 = [
  {
    title: "Data Science",
    description: [formatList(futureDataSkills)],
  },
  {
    title: "Other Skills",
    description: [formatList(futureOtherSkills)],
  },
];

const dataList3 = [
  {
    title: "Professional Proficiency",
    description: [formatList(currentLanguages)],
  },
  {
    title: "Basic to Intermediate Proficiency",
    description: [formatList(futureLanguages)],
  },
  {
    title: "Would love to learn!",
    description: [formatList(aspirationalLanguages)],
  },
];

const SkillsTab = () => {
  return (
    <div>
      <div id="tabrow" className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Core Competencies</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Future Competencies</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Foreign Languages</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <TabPaneler
                        title="Current Skills"
                        description="I'm quite competent in a wide variety of
                      technical skills related to data science, NLP,
                      and machine learning pipelines."
                        dataList={dataList}
                      />
                    </TabPanel>
                    <TabPanel>
                      <TabPaneler
                        title="Future Skills"
                        description="I'm always learning something new. If you'd
                        like to help me continue learning these
                        skills, feel free to contact me."
                        dataList={dataList2}
                      />
                    </TabPanel>

                    <TabPanel>
                      <TabPaneler
                        title="Language Nerd"
                        description="I love learning languages, and am always eager to make use of my linguistic prowess in a professional setting."
                        dataList={dataList3}
                      />
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default SkillsTab;
