import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Layout from "../common/Layout";
import { FaSistrix } from "react-icons/fa";
import TimeSeries from "../dashboard/TimeSeries";
import BarChart from "../dashboard/BarChart";
import Heatmap from "../dashboard/Heatmap";
import CircularBarchart from "../dashboard/CircularBarchart";
import CountryList from "../data/dashboard/CountryList.json";
import createDummyBarChartData from "../datafunctions/createDummyBarchartData";
import BarchartInteractive from "../dashboard/BarChartInteract";
import createDataTimeseries from "../datafunctions/createDataTimeseries";
import CalendarHeatmap from "../dashboard/CalendarHeatmap";
import VoronoiImage from "../dashboard/Voronoi";
import TitleCanvas from "../dashboard/TitleCanvas";
import DirectionalNetwork from "../dashboard/DirectionalNetwork";
import dialogue_network from "../data/dashboard/dialogue_network.json";
import french_verbs from "../data/dashboard/french_verbs.json";
import hierarchical_edge_data from "../data/dashboard/hierarchical_edge_data.json";
import adjective_chords from "../data/dashboard/adjective_chords.json";
import flare from "../data/dashboard/flare.json";
import modernism from "../data/dashboard/modernism.json";
import romanticism from "../data/dashboard/romanticism.json";
import dickens from "../data/dashboard/dickens.json";
import totc from "../data/dashboard/totc.json";
import jane_austen_places from "../data/dashboard/jane_austen_places.json";
import americanrealism from "../data/dashboard/americanrealism.json";
import proust_ngrams from "../data/dashboard/proust_ngrams.json";

import BubbleChart from "../dashboard/BubbleChart";
import CirclePack from "../dashboard/CirclePack";

import HierarchicalEdgeBundling from "../dashboard/HierarchicalEdgeBundling";
import ChordDependency from "../dashboard/ChordDependency";
import ZoomableTreemap from "../dashboard/ZoomableTreemap";
import SequenceSunburst from "../dashboard/SequenceSunburst";
const DashboardTest = () => {
  const ref = useRef(null);
  const refHalf = useRef(null);
  const refThird = useRef(null);
  const refQuarter = useRef(null);
  const refSixth = useRef(null);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [widthHalf, setWidthHalf] = useState(0);
  const [widthThird, setWidthThird] = useState(0);
  const [widthQuarter, setWidthQuarter] = useState(0);
  const [widthSixth, setWidthSixth] = useState(0);

  const [dummyBarChart, setDummyBarChart] = useState([]);
  const [dummyTimeSeries, setDummyTimeSeries] = useState([]);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    // setWidthHalf(refHalf.current.offsetWidth)
    // setWidthThird(refThird.current.offsetWidth)
    // setWidthQuarter(refQuarter.current.offsetWidth)
    // setWidthSixth(refSixth.current.offsetWidth)

    setHeight(ref.current.offsetHeight);
  }, []);

  const randomData = () => {
    setDummyBarChart(createDummyBarChartData());
    setDummyTimeSeries(createDataTimeseries());
  };

  // useEffect(() => {
  //   console.log(dummyTimeSeries)
  // }, [dummyTimeSeries])

  const eventListener = () => {
    if (document.getElementById("chartContainerFull").clientWidth != null) {
      // setWidthHalf(document.getElementById('chartContainerHalf').clientWidth)
      // setWidthThird(document.getElementById('chartContainerThird').clientWidth)
      // setWidthQuarter(
      //   document.getElementById('chartContainerQuarter').clientWidth
      // )
      // setWidthSixth(document.getElementById('chartContainerSixth').clientWidth)
      setWidth(document.getElementById("chartContainerFull").clientWidth);
      setHeight(document.getElementById("chartContainerFull").clientHeight);
    }
    console.log(width);
  };

  useEffect(() => {
    window.addEventListener("resize", eventListener);
    randomData();
    return () => {
      window.removeEventListener("resize", eventListener);
    };
  }, [width]);

  return (
    <Layout>
      <div>
        <div className="container">
          {/* FULL-WIDTH VIZ */}

          <div className="col-lg-12">
            <div className="row mb--10">
              <div className="col-lg-12" ref={ref}>
                <div className="inner" id="chartContainerFull">
                  {/* <div>
                    <BubbleChart
                      height={height}
                      width={width}
                      data={dialogue_network}
                    />
                  </div> */}
                  {/* <div>
                    <CirclePack height={height} width={width} data={totc} />
                  </div> */}
                  <div>
                    <SequenceSunburst
                      height={height}
                      width={width}
                      data={proust_ngrams}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 1/2-WIDTH VIZ */}

          {/* <div className='col-lg-12'>
            <div className='row mb--10'>
              <div className='col-lg-6' ref={refHalf}>
                <div className='inner' id='chartContainerHalf'>
                  <div>
                    <BarchartInteractive
                      height={height}
                      width={widthHalf}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthHalf}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* 1/3-WIDTH VIZ */}
          {/* <div className='col-lg-12'>
            <div className='row mb--10'>
              <div className='col-lg-4' ref={refThird}>
                <div className='inner' id='chartContainerThird'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthThird}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthThird}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthThird}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* 1/4-WIDTH VIZ */}

          {/* <div className='col-lg-12'>
            <div className='row mb--10'>
              <div className='col-lg-3' ref={refQuarter}>
                <div className='inner' id='chartContainerQuarter'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthQuarter}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthQuarter}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthQuarter}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthQuarter}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* 1/6-WIDTH VIZ */}
          {/* <div className='col-lg-12'>
            <div className='row mb--10'>
              <div className='col-lg-2' ref={refSixth}>
                <div className='inner' id='chartContainerSixth'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="view-more-button">
            <a className="btn-default" onClick={randomData}>
              Randomize Data
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DashboardTest;
