import React from 'react'
import SEO from '../common/SEO'
import { Link } from 'react-router-dom'
import { FiArrowRight } from 'react-icons/fi'
import HeaderTopNews from '../common/header/HeaderTopNews'
import HeaderOne from '../common/header/HeaderOne'
import FooterThree from '../common/footer/FooterThree'
import Separator from '../elements/separator/Separator'
import SectionTitle from '../elements/sectionTitle/SectionTitle'
import TimelineTwo from '../elements/timeline/TimelineTwo'
import TabOne from '../elements/tab/TabOne'
import AboutThree from '../elements/about/AboutThree'
import PricingThree from '../elements/pricing/PricingThree'
import BlogList from '../components/blog/itemProp/BlogList'
import BlogClassicData from '../data/blog/BlogList.json'
import Copyright from '../common/footer/Copyright'
import TabTwo from '../elements/tab/TabTwo'

var BlogListData = BlogClassicData.slice(0, 3)

const Technologist = () => {
  return (
    <>
      <SEO title='Technologist' />
      <main className='page-wrapper'>
        <HeaderOne btnStyle='btn-small' HeaderSTyle='header-not-transparent' />

        <Separator />

        <div className='rwt-tab-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Finding Truths in Numbers'
                  title=''
                  description='Looking for creative data science solutions? I just might be your guy!'
                />
              </div>
            </div>
            <div className='row mb--40'>
              <div className='col-lg-12'>
                <TabOne />
              </div>
            </div>
            <div className='row mb--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle=''
                  title='Web Developer'
                  description='Looking for creative data science solutions? I just might be your guy!'
                />
              </div>
            </div>
            <div className='row mb--40'>
              <div className='col-lg-12'>
                <TabTwo />
              </div>
            </div>
            <div className='row mb--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Testing '
                  title='NLP/AI Engineer'
                  description='Looking for creative data science solutions? I just might be your guy!'
                />
              </div>
            </div>
            <div className='row mb--40'>
              <div className='col-lg-12'></div>
            </div>
          </div>
        </div>

        <Separator />

        <Copyright />
      </main>
    </>
  )
}
export default Technologist
