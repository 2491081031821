import React, { useEffect, useState } from 'react'
import SEO from '../common/SEO'
import Layout from '../common/Layout'
import BreadcrumbOne from '../mycomponents/breadcrumb/BreadcrumbOne'
import SectionTitle from '../elements/sectionTitle/SectionTitle'
import AdvanceTabOne from '../mycomponents/nlptabs/AdvanceTabOne'
import AdvanceTabTwo from '../mycomponents/nlptabs/AdvanceTabTwo'
import AdvanceTabThree from '../mycomponents/nlptabs/AdvanceTabThree'
import Separator from '../elements/separator/Separator'

import AccordionTab from '../mycomponents/AccordionTab'

import ProjectAccordion from '../mycomponents/ProjectAccordion'

/// Data Imports
import french_verbs from '../data/dashboard/french_verbs.json'
import posnetwork from '../data/dashboard/posnetwork.json'
import big_heatmap from '../data/dashboard/big_heatmap.json'
import bert_heatmap from '../data/dashboard/bert_heatmap.json'
import dialogue_network from '../data/dashboard/dialogue_network.json'
import internet_languages from '../data/dashboard/internet_languages.json'
import adjective_chords from '../data/dashboard/adjective_chords.json'
import emma_adjective_noun from '../data/dashboard/emma-adjective-noun.json'
import dickens from '../data/dashboard/dickens.json'
import romanticism from '../data/dashboard/romanticism.json'
import totc from '../data/dashboard/totc.json'
import flare from '../data/dashboard/flare.json'
import jane_austen_places from '../data/dashboard/jane_austen_places.json'
import proust_ngrams from '../data/dashboard/proust_ngrams.json'
import americanrealism from '../data/dashboard/americanrealism.json'
import american_realism_min_5 from '../data/dashboard/american_realism_min_5.json'
import american_realism_alphabetized_min_25 from '../data/dashboard/american_realism_alphabetized_min_25.json'
import romanticism_ents_min_5 from '../data/dashboard/romanticism_ents_min_5.json'
import full_lit_alphabetized_GPE from '../data/dashboard/full_lit_alphabetized_GPE.json'
import full_lit_alphabetized_PERSON from '../data/dashboard/full_lit_alphabetized_PERSON.json'
import bubble_proust from '../data/dashboard/bubble_proust.json'

import ZoomableSunburst from '../dashboard/ZoomableSunburst'
import CirclePack from '../dashboard/CirclePack'
import ZoomableIcicle from '../dashboard/ZoomableIcicle'
import StickyNetworkGraph from '../dashboard/StickyNetworkGraph'
import StickyDialogueGraph from '../dashboard/StickyDialogueGraph'
import CharacterHeatmap from '../dashboard/CharacterHeatmap'
import BERTHeatmap from '../dashboard/BERTHeatmap'
import DirectionalNetwork from '../dashboard/DirectionalNetwork'
import LanguageNetwork from '../dashboard/LanguageNetwork'
import CollapsibleTree from '../dashboard/CollapsibleTree'
import ArcDiagram from '../dashboard/ArcDiagram'
import ChordDependency from '../dashboard/ChordDependency'
import BubbleChart from '../dashboard/BubbleChart'
import WordCloud from '../dashboard/WordCloud'
import SequenceSunburst from '../dashboard/SequenceSunburst'
import ZoomableTreemap from '../dashboard/ZoomableTreemap'
import SankeyChart from '../dashboard/SankeyChart'
import HierarchicalEdgeBundling from '../dashboard/HierarchicalEdgeBundling'

const AdvanceTab = () => {
  const [height, setHeight] = useState(document.documentElement.clientHeight)
  const [width, setWidth] = useState(
    document.documentElement.clientWidth -
      Math.round(document.documentElement.clientWidth * 0.25)
  )

  const portfolio_data = [
    {
      title: 'AI and the Media Ecology of Linguistic Power',
      subtitle:
        "Training data for AI models is frequently derived from scraping the entirety of the internet. The project below connects language nodes based on similarity. The size of the nodes corresponds to either the number of web pages in said language or the number of speakers (depending on the data source). Whereas blue nodes correspond to languages 'underrepresented' on the internet, orange nodes are 'overrepresented'. This visualization thus displays the extent to which some languages like English dominate the internet and thus AI models. Such a visualization highlights the need to incorporate more languages into AI models in order to create a more equitable and inclusive digital future.",
      graph: [
        <LanguageNetwork
          height={height}
          width={width}
          data={internet_languages}
          undertitle={
            'Whereas the orange nodes represent languages that are overrepresented on the internet relative to the number of speakers, Blue nodes are underrepresented languages.'
          }
        />,
      ],
      link: 'github-linguistic',
      button: 'See Code',
      interaction: ' Click on the nodes to drag and drop.',
    },
    {
      title: 'Visualizing Transformers',
      subtitle:
        'Multi-headed attention is the foundation of modern NLP tools. The project below is a simple approach to visualizing the relationships between tokens as interpreted by the BERT model. Notice how different attention layers highlight different semantic relationships.',
      graph: [
        <BERTHeatmap height={height} width={width} data={bert_heatmap} />,
      ],
      interaction:
        ' Click the button to loop through the various attention layers.',
      link: 'github-bert-heatmap',
      button: 'See Code',
    },
    {
      title: "America's Twitter Diplomacy Through Word Embeddings",
      subtitle:
        "As an international politics nerd, I have a great time exploring the Twitter data of the world's embassies. The project below uses Word2Vec to find the relationships in vector space between words used by the Twitter accounts of U.S. embassies. Notice how embassies use drastically different language in association with different countries depending on whether they're considered geopolitical allies or adversaries.",
      graph: [
        <StickyNetworkGraph height={height} width={width} data={posnetwork} />,
      ],
      interaction: ' Click on the nodes to drag and drop.',
      link: 'github-social-science',
      button: 'See Code',
    },
    {
      title: 'Circle Packing with Charles Dickens',
      subtitle:
        "Python libraries such as spaCy allow us to break down language into its constituent parts. Using hierarchical data visualization methods such as circle packing, we can dynamically explore entire corpora. In the example below, you'll find an interactive representation of Dickens' 'The Tale of Two Cities' that allows us to explore the book's parts of speech and named entities. The same logic can be applied to a wide variety of NLP projects.",
      graph: [<CirclePack height={height} width={width} data={totc} />],
      interaction: ' Click on the bubble to zoom.',
      link: 'github-literary',
      button: 'See Code',
    },
    {
      title: 'Shakespearean Dialogue Networks',
      subtitle:
        "The project below shows how raw text data can be converted into network data that shows the interactions between entities. This project deconstructs Shakespeare's 'Romeo and Juliet' to map the conversations between characters. This same logic can be applied to a wide variety of NLP projects that seek to understand interactions or dialogue found within text corpora.",
      graph: [
        <StickyDialogueGraph
          height={height}
          width={width}
          data={dialogue_network}
        />,
      ],
      interaction: 'Click on the nodes to drag and drop.',
      link: 'github-literary',
      button: 'See Code',
    },
    {
      title: 'Named Entity Sunburst',
      subtitle:
        "This zoomable sunburst diagram allows us to explore all the place names found in a cross-section of English literature. It's interesting to see the places that are mentioned most frequently in various books.",
      graph: [
        <ZoomableSunburst
          height={height}
          width={width}
          data={full_lit_alphabetized_GPE}
        />,
      ],

      interaction:
        ' Click on a slice of the sunburst to zoom in for more detail.',
      link: 'github-literary',
      button: 'See Code',
    },
    {
      title: 'Orthographic Heatmap',
      subtitle:
        'Using a heatmap to visualize the co-occurrence of characters in a given language may seem like a simple concept, but such co-occurrence of either tokens or characters is the foundation of large language models. The probabilistic relationship between such entities allows for inferences related to natural language understanding and generation. The project below is a simple representation of such statistical relationships and offers some insight into the phonetic possibilities of various languages. For example, notice the extent to which Italian words end in vowels.',
      graph: [
        <CharacterHeatmap height={height} width={width} data={big_heatmap} />,
      ],
      interaction: ' Click the button to change the language input.',
      link: 'github-orthographic',
      button: 'See Code',
    },

    {
      title: "Mark Twain's Vocabulary",
      subtitle:
        "This project breaks down all the vocabulary in Mark Twain's oeuvre into alphabetized parts of speech and named entities. This allows us to dynamically dive into Twain's word usage.",
      graph: [
        <ZoomableIcicle
          height={height}
          width={width}
          data={american_realism_alphabetized_min_25}
        />,
      ],
      interaction:
        ' Click on a slice of the visualization to explore greater detail.',
      link: 'github-literary',
      button: 'See Code',
    },
    {
      title: 'Visualizing Word Co-Occurrence with Chord Dependency Diagrams',
      subtitle:
        "Different authors use different descriptors. The project below maps the relationship between adjectives and nouns in Jane Austen's 'Emma'.",
      graph: [
        <ChordDependency
          height={height}
          width={width}
          data={emma_adjective_noun}
        />,
      ],
      interaction: '',
      link: 'github-literary',
      button: 'See Code',
    },
    {
      title: 'Bubble Chart Dictionary',
      subtitle:
        "This bubble chart takes the most common nouns in Marcel Proust's 'Du côté de chez Swann' and links directly to Wiktionary. This is simply a fun approach to language learning that allows us to prioritize frequency.",
      graph: [
        <BubbleChart height={height} width={width} data={bubble_proust} />,
      ],
      interaction: ' Click on a bubble to link to Wiktionary.',
      link: 'github-literary',
      button: 'See Code',
    },
    {
      title: 'Sequences Sunburst for French N-Grams',
      subtitle:
        "As a language-learner, I think it's important to familiarize oneself with particularly common language patterns. By finding the most common four-word sequences in Marcel Proust's 'Du côté de chez Swann' we can explore these patterns in a dynamic visualization.",
      graph: [
        <SequenceSunburst height={height} width={width} data={proust_ngrams} />,
      ],
      interaction:
        ' Hover over the outer edge of the sunburst to explore the most common n-grams.',
      link: 'github-literary',
      button: 'See Code',
    },
    {
      title: "Who's Who in English Literature",
      subtitle:
        'This zoomable treemap allows us to explore the characters mentioned in a cross-section of English literature.',
      graph: [
        <ZoomableTreemap
          height={height}
          width={width}
          data={full_lit_alphabetized_PERSON}
        />,
      ],
      interaction: 'Click on a section of the treemap to dive deeper.',
      link: 'github-literary',
      button: 'See Code',
    },
    // {
    //   title: "5. Collapsible Tree Hierarchy",
    //   subtitle: "BLAH BLAH BLAH Something with only named entities",
    //   graph: [
    //     <CollapsibleTree
    //       height={height}
    //       width={width}
    //       data={jane_austen_places}
    //     />,
    //   ],
    //   interaction: " CHL",
    //   link: "banana",
    //   button: "See Code",
    // },
    // {
    //   title: "15. Word Cloud (In Progress)",
    //   subtitle:
    //     "BLAH BLAH BLAH This is content about this awesome project.BLAH BLAH BLAH This is content about this awesome project.",
    //   graph: [
    //     // <WordCloud height={height} width={width} data={dialogue_network} />,
    //   ],
    //   interaction: " CHL",
    //   link: "banana",
    //   button: "See Code",
    // },
    // {
    //   title: "10. Arc Diagram (In Progress)",
    //   subtitle:
    //     "BLAH BLAH BLAH This is content about this awesome project.BLAH BLAH BLAH This is content about this awesome project.",
    //   graph: [
    //     <ArcDiagram height={height} width={width} data={dialogue_network} />,
    //   ],
    //   interaction: " CHL",
    //   link: "banana",
    //   button: "See Code",
    // },
    // {
    //   title: "18. Sankey (In Progress)",
    //   subtitle:
    //     "BLAH BLAH BLAH This is content about this awesome project.BLAH BLAH BLAH This is content about this awesome project.",
    //   graph: [
    //     // <SankeyChart height={height} width={width} data={dialogue_network} />,
    //   ],
    //   interaction: " CHL",
    //   link: "banana",
    //   button: "See Code",
    // },
    // {
    //   title: "19. Tangled Tree (In Progress)",
    //   subtitle:
    //     "BLAH BLAH BLAH This is content about this awesome project.BLAH BLAH BLAH This is content about this awesome project.",
    //   graph: [<CirclePack height={height} width={width} data={french_verbs} />],
    //   interaction: " CHL",
    //   link: "banana",
    //   button: "See Code",
    // },
    // {
    //   title: "20. Force-Directed Tree (In Progress)",
    //   subtitle:
    //     "BLAH BLAH BLAH This is content about this awesome project.BLAH BLAH BLAH This is content about this awesome project.",
    //   graph: [<CirclePack height={height} width={width} data={french_verbs} />],
    //   interaction: " CHL",
    //   link: "banana",
    //   button: "See Code",
    // },
  ]

  useEffect(() => {}, [])
  return (
    <>
      <SEO title='Data Science and NLP' />
      <Layout>
        <BreadcrumbOne
          title='NLP Portfolio'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Advance Tab'
        />

        <div className='main-content'>
          {/* Start Elements Area  */}
          <div className='rwt-advance-tab-area rn-section-gap'>
            <div className='container'>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Finding Patterns'
                    title=''
                    description={[
                      "NLP engineers break down language data to find patterns useful for applications and analysis. Check out some of the projects below. I'm always adding more projects, so check back for updates. NOTE: These interactive visualizations are much more fun on a desktop.",
                    ]}
                  />
                </div>
              </div>
              <ProjectAccordion data={portfolio_data} />
            </div>
          </div>
          {/* End Elements Area  */}

          <Separator />

          {/* Start Elements Area  */}
          {/* <div className='rwt-advance-tab-area rn-section-gap'>
            <div className='container'>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='You can Customize All'
                    title='Advance Tab Style One.'
                    description=''
                  />
                </div>
              </div>
            </div>
          </div> */}
          {/* End Elements Area  */}
        </div>
      </Layout>
    </>
  )
}
export default AdvanceTab
