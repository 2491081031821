import React from 'react'
import { FiActivity, FiCast, FiMap } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll'

const ServiceList = [
  {
    icon: <FiActivity />,
    title: 'Teacher',
    description:
      'I have taught in schools throughout the world and am always exploring new opportunities to do so.',
  },
  {
    icon: <FiCast />,
    title: 'Private Tutor',
    description:
      'I tutor a variety of subjects at the pre-college level. I also teach Business English to private clients across the world.',
  },
  {
    icon: <FiMap />,
    title: 'Writing Coach',
    description:
      'I provide academic editing services to students and academics from a variety of disciplines and backgrounds.',
  },
]

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
      {ServiceList.map((val, i) => (
        <div className='col-lg-4 col-md-6 col-sm-6 col-12' key={i}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className='icon'>{val.icon}</div>
              <div className='content'>
                <h4 className='title w-600'>
                  <Link
                    to='#service'
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
                <p
                  className='description b1 color-gray mb--0'
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  )
}
export default ServiceOne
