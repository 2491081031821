import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import debounce from "lodash/debounce";

const ZoomableTreemap = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);

  const margin = { top: 90, right: 50, bottom: 50, left: 50 },
    width = props.width - margin.left - margin.right,
    height = 1000 - margin.top - margin.bottom,
    count = 0;

  const idnumber = (id) => {
    this.id = id;
    this.href = new URL(`#${id}`) + "";
  };

  const uid = (name) => {
    return new idnumber("O-" + (name == null ? "" : name + "-") + ++count);
  };

  //   Id.prototype.toString = function () {
  //     return 'url(' + this.href + ')'
  //   }

  const tile = (node, x0, y0, x1, y1) => {
    d3.treemapBinary(node, 0, 0, width, height);
    for (const child of node.children) {
      child.x0 = x0 + (child.x0 / width) * (x1 - x0);
      child.x1 = x0 + (child.x1 / width) * (x1 - x0);
      child.y0 = y0 + (child.y0 / height) * (y1 - y0);
      child.y1 = y0 + (child.y1 / height) * (y1 - y0);
    }
  };
  const treemap = (data) =>
    d3.treemap().tile(tile)(
      d3
        .hierarchy(data)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value)
    );

  const format = d3.format(",d");

  const name = (d) =>
    d
      .ancestors()
      .reverse()
      .map((d) => d.data.name)
      .join("/");

  useEffect(() => {
    let data = props.data;

    const x = d3.scaleLinear().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([0, height]);
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const svg = svgEl
      .append("g")
      //   .attr('transform', `translate(${margin.left},${margin.top})`)
      .attr("viewBox", [width / 2, -width / 2, width, height])
      .style("font", "10px sans-serif");

    let group = svg.append("g").call(render, treemap(data));

    function render(group, root) {
      const node = group
        .selectAll("g")
        .data(root.children.concat(root))
        .join("g");

      node
        .filter((d) => (d === root ? d.parent : d.children))
        .attr("cursor", "pointer")
        .on("click", (event, d) => (d === root ? zoomout(root) : zoomin(d)));

      node.append("title").text((d) => `${name(d)}\n${format(d.value)}`);

      node
        .append("rect")
        // .attr('id', (d) => (d.leafUid = uid('leaf').id))
        .attr("id", (d) => d.id)
        .attr("fill", (d) =>
          d === root ? "#fff" : d.children ? "#ccc" : "#ddd"
        )
        .attr("stroke", "#fff");

      node
        .append("clipPath")
        // .attr('id', (d) => (d.clipUid = uid('clip').id))
        .attr("id", (d) => d.id)
        .append("use");
      // .attr('xlink:href', (d) => d.leafUid.href)

      node
        .append("text")
        .attr("clip-path", (d) => d.clipUid)
        .attr("font-weight", (d) => (d === root ? "bold" : null))
        .selectAll("tspan")
        .data((d) =>
          (d === root ? name(d) : d.data.name)
            .split(/(?=[A-Z][^A-Z])/g)
            .concat(format(d.value))
        )
        .join("tspan")
        .attr("x", 3)
        .attr(
          "y",
          (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`
        )
        .attr("fill-opacity", (d, i, nodes) =>
          i === nodes.length - 1 ? 0.7 : null
        )
        .attr("font-weight", (d, i, nodes) =>
          i === nodes.length - 1 ? "normal" : null
        )
        .text((d) => d);

      group.call(position, root);
    }

    function position(group, root) {
      group
        .selectAll("g")
        .attr("transform", (d) =>
          d === root ? `translate(0,-100)` : `translate(${x(d.x0)},${y(d.y0)})`
        )
        .select("rect")
        .attr("width", (d) => (d === root ? width : x(d.x1) - x(d.x0)))
        .attr("height", (d) => (d === root ? 100 : y(d.y1) - y(d.y0)));
    }

    // When zooming in, draw the new nodes on top, and fade them in.
    function zoomin(d) {
      const group0 = group.attr("pointer-events", "none");
      const group1 = (group = svg.append("g").call(render, d));

      x.domain([d.x0, d.x1]);
      y.domain([d.y0, d.y1]);

      svg
        .transition()
        .duration(750)
        .call((t) => group0.transition(t).remove().call(position, d.parent))
        .call((t) =>
          group1
            .transition(t)
            .attrTween("opacity", () => d3.interpolate(0, 1))
            .call(position, d)
        );
    }

    // When zooming out, draw the old nodes on top, and fade them out.
    function zoomout(d) {
      const group0 = group.attr("pointer-events", "none");
      const group1 = (group = svg.insert("g", "*").call(render, d.parent));

      x.domain([d.parent.x0, d.parent.x1]);
      y.domain([d.parent.y0, d.parent.y1]);

      svg
        .transition()
        .duration(750)
        .call((t) =>
          group0
            .transition(t)
            .remove()
            .attrTween("opacity", () => d3.interpolate(1, 0))
            .call(position, d)
        )
        .call((t) => group1.transition(t).call(position, d.parent));
    }
  }, [props.data, props.height, props.width]);

  return (
    <div
      id="chartContainer"
      className={classes.circlePackContainer}
      ref={containerRef}
    >
      <div id="ZoomableTreemap">
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            transform={`translate(${margin.left}, ${margin.top + 20})`}
          />
        </svg>
      </div>
    </div>
  );
};

export default ZoomableTreemap;
