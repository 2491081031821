import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import classes from './Dashboard.module.css'
import debounce from 'lodash/debounce'

const BarchartInteractive = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null)
  const containerRef = useRef(null)

  const [checked, setChecked] = useState(false)

  const handleChange = () => {
    setChecked(!checked)
  }

  const margin = { top: 90, right: 50, bottom: 50, left: 50 },
    width = props.width - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom,
    formatPercent = d3.format('.0%'),
    x = d3.scaleBand().rangeRound([0, width]).padding(0.1),
    y = d3.scaleLinear().range([height, 0]),
    xAxis = d3.axisBottom(x),
    yAxis = d3.axisLeft(y).tickFormat(formatPercent)

  const dummyData = [
    { letter: 'A', frequency: 0.08167 },
    { letter: 'B', frequency: 0.01492 },
    { letter: 'C', frequency: 0.02782 },
    { letter: 'D', frequency: 0.04253 },
    { letter: 'E', frequency: 0.12702 },
    { letter: 'F', frequency: 0.02288 },
    { letter: 'G', frequency: 0.02015 },
    { letter: 'H', frequency: 0.06094 },
    { letter: 'I', frequency: 0.06966 },
    { letter: 'J', frequency: 0.00153 },
    { letter: 'K', frequency: 0.00772 },
    { letter: 'L', frequency: 0.04025 },
    { letter: 'M', frequency: 0.02406 },
    { letter: 'N', frequency: 0.06749 },
    { letter: 'O', frequency: 0.07507 },
    { letter: 'P', frequency: 0.01929 },
    { letter: 'Q', frequency: 0.00095 },
    { letter: 'R', frequency: 0.05987 },
    { letter: 'S', frequency: 0.06327 },
    { letter: 'T', frequency: 0.09056 },
    { letter: 'U', frequency: 0.02758 },
    { letter: 'V', frequency: 0.00978 },
    { letter: 'W', frequency: 0.0236 },
    { letter: 'X', frequency: 0.0015 },
    { letter: 'Y', frequency: 0.01974 },
    { letter: 'Z', frequency: 0.00074 },
  ]

  useEffect(() => {
    let data = dummyData
    const svgEl = d3.select(svgRef.current)
    svgEl.selectAll('*').remove()

    const svg = svgEl
      .append('g')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')

      .attr('transform', `translate(${margin.left},${margin.top})`)

    data.forEach(function (d) {
      d.frequency = +d.frequency
    })

    x.domain(
      data.map(function (d) {
        return d.letter
      })
    )
    y.domain([
      0,
      d3.max(data, function (d) {
        return d.frequency
      }),
    ])

    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)

    svg
      .append('g')
      .attr('class', 'y axis')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '.71em')
      .style('text-anchor', 'end')
      .text('Frequency')

    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('fill', 'red')
      .attr('x', function (d) {
        return x(d.letter)
      })
      .attr('width', x.bandwidth())
      .attr('y', function (d) {
        return y(d.frequency)
      })
      .attr('height', function (d) {
        return height - y(d.frequency)
      })

    d3.select('input').on('change', change)

    const sortTimeout = setTimeout(function () {
      d3.select('input').property('checked', true).each(change)
    }, 2000)

    function change() {
      clearTimeout(sortTimeout)

      // Copy-on-write since tweens are evaluated after a delay.
      var x0 = x
        .domain(
          data
            .sort(
              this.checked
                ? function (a, b) {
                    return b.frequency - a.frequency
                  }
                : function (a, b) {
                    return d3.ascending(a.letter, b.letter)
                  }
            )
            .map(function (d) {
              return d.letter
            })
        )
        .copy()

      svg.selectAll('.bar').sort(function (a, b) {
        return x0(a.letter) - x0(b.letter)
      })

      var transition = svg.transition().duration(750),
        delay = function (d, i) {
          return i * 50
        }

      transition
        .selectAll('.bar')
        .delay(delay)
        .attr('x', function (d) {
          return x0(d.letter)
        })

      transition.select('.x.axis').call(xAxis).selectAll('g').delay(delay)
    }
  }, [props.data, props.height, props.width])

  return (
    <div
      id='chartContainer'
      className={classes.chartContainer}
      ref={containerRef}
    >
      <div id='BarchartInteractive'>
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>
      <div>
        <div id='sorter' className='col-lg-6 ml--20'>
          <input type='checkbox' checked={checked} onChange={handleChange} />

          <button className='btn-default btn-small' onClick={handleChange}>
            sort
          </button>
        </div>
      </div>
    </div>
  )
}

export default BarchartInteractive
