import React, { useEffect, useState } from 'react'
import Layout from '../common/Layout'
import { FaSistrix } from 'react-icons/fa'
import TimeSeries from '../dashboard/TimeSeries'
import BarChart from '../dashboard/BarChart'
import Heatmap from '../dashboard/Heatmap'
import CircularBarchart from '../dashboard/CircularBarchart'
import CountryList from '../data/dashboard/CountryList.json'
// import data from "../data/dashboard/custom.json";
//import us from "../data/dashboard/states-albers-10m.json";
//import us from "../data/dashboard/world.json";
import us from '../data/dashboard/Bhutan.json'
import embassynetwork from '../data/dashboard/embassynetwork.json'
import posnetwork from '../data/dashboard/posnetwork.json'
import NetworkGraph3 from '../dashboard/NetworkGraph3'

import timedata from '../data/dashboard/sfhh@4.json'
import french_verbs from '../data/dashboard/french_verbs.json'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import CirclePack from '../dashboard/CirclePack'

import SectionTitle from '../elements/sectionTitle/SectionTitle'
import Voronoi from '../dashboard/Voronoi'
import TimeNetwork from '../dashboard/TimeNetwork'
import AITab from '../elements/tab/AITab'

import classes from './TestPage.module.css'
import SkillsTab from '../elements/tab/SkillsTab'
import DevTab from '../elements/tab/DevTab'

const TestPage = () => {
  const Countries = CountryList[0].text.split(',')
  const Fruit = CountryList[0].fruit.split(',')
  const [height, setHeight] = useState(document.documentElement.clientHeight)
  const [width, setWidth] = useState(
    document.documentElement.clientWidth -
      Math.round(document.documentElement.clientWidth * 0.25)
  )
  useEffect(() => {}, [])

  const [dummyBarChart, setDummyBarChart] = useState([])

  const createDummyBarChartData = () => {
    const ARRAY_LENGTH = 10
    const randomArray = []
    for (let i = 0; i < ARRAY_LENGTH; i++) {
      randomArray.push(Math.random())
    }
    const listArray = Array.from(Array(10).keys())

    function func(arr1, arr2) {
      const obj = []
      arr1.forEach((Curr_element, index) => {
        obj[index] = { x: arr1[index], y: arr2[index] }
      })
      return obj
    }
    const jsondum = func(listArray, randomArray)
    setDummyBarChart(jsondum)
  }

  const randomData = () => {
    createDummyBarChartData()
  }

  return (
    <Layout>
      <div>
        <div className='container'>
          <div>
            <div className='col-lg-12'>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  <div className='inner'>
                    <div>
                      <BarChart
                        height={height}
                        width={width}
                        data={dummyBarChart}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TestPage
