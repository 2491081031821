import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceOne from "../elements/service/ServiceOne";
import AboutFour from "../elements/about/AboutFour";
import GalleryOne from "../elements/gallery/GalleryOne";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import AdvanceTabOne from "../mycomponents/nlptabs/AdvanceTabOne";

import ProjectAccordion from "../mycomponents/ProjectAccordion";
var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
  {
    image: "/myphotos/mongoliateacher.jpeg",
    title: "Teacher",
    subtitle:
      "I've taught English and other subjects in schools throughout the world.",
    text: [
      "<p> It sounds counterintuitive, but I learn the most when I’m working as a teacher. Not only do the students force me to develop expertise in whichever subject I’m teaching, but engaging with students has greatly improved my emotional intelligence and understanding of the world.</p><br/><p>I’ve been the full-time classroom teacher for students as young as 4th grade and though my specialty is English and language arts, I’ve taught mathematics, music, programming, theater, science, and history as well. </p><br/><p>My teaching philosophy is rooted in a mix of Montessori-style self-driven education as well as the cognitive neuroscience of language acquisition. I believe that students can almost always learn anything through creatively designed games and group activities. </p><br/><p>Having managed classrooms of more than forty students and having directed student productions with more than a hundred, I’m quite confident in my ability to create an orderly and nurturing learning environment that will inspire students to be their best selves.</p><br/><p>If you’re interested in hiring me as a full-time teacher, please don’t hesitate to contact me.</p>",
    ],
    button: "Contact Me",
    link: "/contact",
  },
  {
    image: "/myphotos/tshewang.jpg",
    title: "Private Tutor",
    subtitle: "For the last decade I've worked as a private English tutor",
    text: [
      "<p>In addition to being a classroom teacher, I have more than a decade of experience teaching students in a small private setting (both in-person and online) and adapting my curricula to suit the needs of any particular student.</p><br/><p>Having single-handedly brought English-learning students from zero-to-fluent, I’m very confident in my skills as an English teacher. Moreover, I’ve taught a variety of subjects beyond English including French, Spanish, mathematics (including calculus and linear algebra), science (basic chemistry and biology), and history.</p><br/><p>My clients have included college students, elementary schoolers, scholars looking to refine their English-language writing abilities, and business executives looking to bring their business English to the next level.</p><br/><p>If you’re looking for a world-class tutor for both English as well as a variety of other subjects, don’t hesitate to reach out for information about my hourly rates.</p>",
    ],
    button: "Contact Me",
    link: "/contact",
  },
  {
    image: "/myphotos/editor.jpg",
    title: "Writing Coach.",
    subtitle:
      "We help our clients succeed by creating brand identities, <br />  digital experiences, and print materials.",
    text: [
      "<p>Academic writing is a challenge even for native English speakers. Although I prefer serving students and scholars as an in-person writing coach, I also provide bespoke editing services for a variety of academic clients mainly based in Asia. </p><br/><p>Whether you’re trying to elegantly reduce your word count for publication purposes or simply add the finishing touches to your term paper, I’ll refine your prose and make your ideas sparkle. I’ve been the editor for experts in a variety of domains ranging from the humanities to the natural sciences. Moreover, I have a strong understanding of the citation conventions expected by a variety of journals and will ensure that your writing meets the given standards for publication. </p><br/><p>If you’re interested in hiring me as an academic editor, please contact me for my rates (which vary according to subject matter and word count). I would also be happy to provide references upon request.</p>",
    ],
    button: "Contact Me",
    link: "/contact",
  },
];

const TravelAgency = () => {
  return (
    <>
      <SEO title="Educator" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />
        <Separator />
        <div className="blog-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="World Class"
                  title="Educator"
                  text="Looking for a top-notch teacher, tutor, or academic editor?"
                />
              </div>
            </div>
            {/* <div>
              <ProjectAccordion data={BannerData} />
            </div> */}
            <div className="row row--15">
              {BlogListData.map((item) => (
                <div
                  key={item.id}
                  className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                >
                  <BlogList StyleVar="box-card-style-default" data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Separator />
        <Separator />
        <Copyright />
      </main>
    </>
  );
};

export default TravelAgency;
