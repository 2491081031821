import React from 'react'
import { slugify } from '../utils'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const BlogItem = ({ data, StyleVar }) => {
  // const cate = data.categories.map((value, i) => {
  //   return (
  //     <Link to={process.env.PUBLIC_URL + `/category/${slugify(value)}`} key={i}>
  //       {value}
  //       {i !== data.categories.length - 1 && ','}
  //     </Link>
  //   )
  // })
  const styleVar2 = {
    border: 10,
  }

  return (
    <div
      className={`rn-card rounded-5 border border-2 border-primary ${styleVar2}`}
    >
      <div className='inner'>
        <Link to={process.env.PUBLIC_URL + `/${data.link}`} className='image'>
          <div className='thumbnail'>
            {/* <img
              src={`${process.env.PUBLIC_URL}/${data.image}`}
              alt='Blog Image'
            /> */}
            {data.component}
          </div>
          <div className='content'>
            <h4 className='title'>
              <Link to={`/${data.link}`}>{data.title}</Link>
            </h4>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default BlogItem
