import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from 'react-router-dom'
import TabPaneler from './TabPaneler'

const dataList = [
  {
    title: "BERT's CLS Vectors for Rhetorical Analysis",
    description:
      'Even off-the-shelf BERT creates rich encodings of text that allow for complex classification tasks.',
  },
  {
    title: 'Masked-Language Modeling for EdTech',
    description: 'Using BERT to create part of a question-generation pipeline.',
  },
]

const dataList2 = [
  {
    title: 'SimpsonsGPT',
    description: [
      "<p>This GPT model was finetuned on script summaries from The Simpsons. With a starter prompt, the model generates an episode of the Simpsons.</p><p>GPT isn't intelligent, but it's great at learning idiosyncratic language patterns.</p><p>Check out some of the code and outputs. A lot of the episodes are pretty funny, even if they don't always make sense.</p>",
    ],
    link: 'github-simpsons',
  },
  {
    title: "Generating Fake 'The Economist' Articles",
    description: [
      "<p>This finetuned GPT model generates world news articles in the dour precision of an Etonian Thatcherite.</p><p>This project was a great way to explore GPTs' capacity for creating misinformation content. </p><p>Check out the code and try making your own articles.</p>",
    ],
    link: 'github-economist',
  },
]

const dataList3 = [
  {
    title: 'Dzongkha Romanization',
    description: [
      "<p>Consistent romanization is an important tool for incorporating 'low-resource' languages into many AI applications.</p><p>This finetuned T5 model transliterates the Dzongkha language to the roman alphabet and vice-versa</p><p>Check out the code</p>",
    ],
  },
  {
    title: 'T5 for Conditional Text-Generation',
    description: [
      "<p>I'm a big fan of The Economist, despite their know-it-all-ism. That's why I finetuned a GPT model to generate world news articles in the dour precision of an Etonian Thatcherite.</p><p>This project was a great way to explore GPTs' capacity for creating misinformation content. </p><p>Check out the code and try making your own articles.</p>",
    ],
  },
]

const AITab = () => {
  return (
    <div>
      <div id='tabrow' className='row'>
        <div className='col-lg-12'>
          <Tabs>
            <div className='row row--30 align-items-center'>
              <div className='col-lg-12'>
                <div className='rn-default-tab style-three'>
                  <div className='tab-button-panel'>
                    <TabList className='tab-button'>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>BERT</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>GPT</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>T5</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className='tab-content-panel'>
                    <TabPanel>
                      <TabPaneler
                        title={'Encoders'}
                        dataList={dataList}
                        description={
                          'Encoders like BERT enable powerful sentiment analysis and search engine capabilities. Check out these examples of how I like to use BERT.'
                        }
                        codeButton={true}
                      />
                    </TabPanel>
                    <TabPanel>
                      <TabPaneler
                        title={'Decoders'}
                        dataList={dataList2}
                        description={
                          'Decoders like GPT are the cutting-edge of auto-complete. Complete a sentence, or complete a screenplay. Fine-tuning GPT with prompts allows for supremely complex applications in text generation. Check out these examples of how I like to use GPT.'
                        }
                        codeButton={true}
                      />
                    </TabPanel>
                    <TabPanel>
                      <TabPaneler
                        title={'Encoder-Decoder'}
                        dataList={dataList3}
                        description={
                          'The Encoder-Decoder transformer architecture allows for top-notch Sequence-to-Sequence performance that is applicable to use cases such as translation and summarization.'
                        }
                        codeButton={true}
                      />
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default AITab
