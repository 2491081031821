import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import { color } from "d3";

const Heatmap = (props) => {
  const options = [
    "finnish",
    "french",
    "italian",
    "dzongkha",
    "greek",
    "polish",
    "telugu",
    "russian",
    "farsi",
    "hindi",
  ];

  const [dataChoice, setDataChoice] = useState(0);
  const [data, setData] = useState(props.data[dataChoice]);
  const [title, setTitle] = useState(options[0]);
  const tooltipRef = useRef();

  const d3Chart = useRef();
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const margin = { top: 50, right: 50, bottom: 50, left: 50 },
    width = props.width > 1000 ? 700 : props.width - margin.left - margin.right,
    height =
      props.width > 1000 ? 700 : props.width - margin.top - margin.bottom;

  const changeData = () => {
    if (dataChoice == 0 || dataChoice + 1 < options.length) {
      setDataChoice(dataChoice + 1);
    } else {
      setDataChoice(0);
    }
  };

  useEffect(() => {
    setData(props.data[dataChoice]);
    setTitle(options[dataChoice]);
  }, [dataChoice]);

  useEffect(() => {
    const languages = d3.map(data, (d) => {
      return d.y;
    });
    const levels = d3.map(data, (d) => {
      return d.x;
    });
    const maxColor = Math.max(...data.map((o) => o.color));

    const myColor = d3
      .scaleSequential()
      .interpolator(d3.interpolateYlOrRd)
      .domain([1, maxColor]);
    const xScale = d3.scaleBand().range([0, width]).domain(levels).padding(0.2);
    const yScale = d3
      .scaleBand()
      .range([height, 0])
      .domain(languages.sort().reverse())
      .padding(0.2);

    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const svg = svgEl
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom + 50)
      .append("g");
    // .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // .attr("transform", `translate(${margin.left},${margin.top})`);

    const xAxis = svg
      .append("g")
      .style("font-size", 15)

      // .style("margin-top", "1rem")
      // .attr('transform', 'translate(0,' + width + ')')
      .call(d3.axisTop(xScale).tickSize(0))
      .select(".domain")
      .remove();

    const yAxis = svg
      .append("g")
      .style("font-size", 15)
      .call(d3.axisLeft(yScale).tickSize(0))
      .select(".domain")
      .remove();

    const mouseover = function (event, d) {
      const tooltipDiv = tooltipRef.current;
      if (tooltipDiv) {
        d3.select(tooltipDiv).transition().duration(200).style("opacity", 0.9);
        d3.select(tooltipDiv)
          .html(
            `${event.target.__data__.color} instances of "${event.target.__data__.x}${event.target.__data__.y}"`
          )
          // TODO: some logic when the tooltip could go out from container
          .style("left", event.layerX + "px")
          .style("top", event.layerY - 28 + "px");
      }
      // console.log(event)
    };

    const mouseout = () => {
      const tooltipDiv = tooltipRef.current;
      if (tooltipDiv) {
        d3.select(tooltipDiv).transition().duration(500).style("opacity", 0);
      }
    };

    svg
      .selectAll()
      .data(data, function (d) {
        return d.x + ":" + d.y;
      })
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return xScale(d.x);
      })
      .attr("y", function (d) {
        return yScale(d.y);
      })
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("width", xScale.bandwidth())
      .attr("height", yScale.bandwidth())
      .style("fill", function (d) {
        return myColor(d.color);
      })
      .style("stroke-width", 4)
      .style("stroke", "none")
      .style("opacity", 0.8)
      .on("mouseover", mouseover)
      // .on("mousemove", mousemove)
      .on("mouseleave", mouseout);
  }, [data, props.width, dataChoice]);

  return (
    <div>
      <div
        id="chartContainer"
        className={classes.characterHeatmap}
        ref={containerRef}
      >
        <div className="row ml--10 mr--10 mb--5 mt--30">
          <h2>{title.toUpperCase()}</h2>
        </div>
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            transform={`translate(${margin.left}, ${margin.top})`}
            ref={svgRef}
            id={"d3Chart"}
          />
        </svg>
        <button className={classes.tooltip} ref={tooltipRef}></button>
        <div className="row ml--10 mr--10 mb--5 mt--10">
          <button
            className="btn-default btn-small mr--10 ml--10"
            onClick={changeData}
          >
            Change Data Input
          </button>
        </div>
      </div>
    </div>
  );
};

export default Heatmap;
