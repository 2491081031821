import React, { useEffect, useState } from 'react'
import SEO from '../common/SEO'
import { Link } from 'react-router-dom'

import HeaderTopNews from '../common/header/HeaderTopNews'
import HeaderOne from '../common/header/HeaderOne'
import FooterTwo from '../common/footer/FooterTwo'
import Copyright from '../common/footer/Copyright'
import Slider from 'react-slick'
import { BannerActivation } from '../utils/script'
import Separator from '../elements/separator/Separator'
import SectionTitle from '../elements/sectionTitle/SectionTitle'
import ServiceOne from '../elements/service/ServiceOne'
import AboutFour from '../elements/about/AboutFour'
import GalleryOne from '../elements/gallery/GalleryOne'
import TestimonialThree from '../elements/testimonial/TestimonialThree'
import BlogList from '../components/blog/itemProp/BlogList'
import BlogClassicData from '../data/blog/BlogList.json'
import BlogItem from '../mycomponents/BlogItem'

import timedata from '../data/dashboard/sfhh@4.json'
import TimeNetwork from '../dashboard/TimeNetwork'
import AboutTechnologist from '../elements/about/AboutTechnologist'
import AboutTechnologist2 from '../elements/about/AboutTechnologist2'
import Competencies from '../mycomponents/Competencies'

const PortfolioMain = () => {
  const [height, setHeight] = useState(document.documentElement.clientHeight)
  const [width, setWidth] = useState(
    document.documentElement.clientWidth -
      Math.round(document.documentElement.clientWidth * 0.25)
  )

  const timeNetwork = [
    <TimeNetwork
      height={height}
      width={width}
      data={timedata}
      colorOne={'white'}
      colorTwo={'black'}
      radius={4}
      timeStep={300}
    />,
  ]

  const BannerData = [
    {
      image: '',
      link: 'technologist-nlp',
      title: 'NLP / Data Science',
      description: '',
      component: [
        <TimeNetwork
          height={height}
          width={width}
          data={timedata}
          colorOne={'white'}
          colorTwo={'black'}
          radius={4}
          timeStep={300}
        />,
      ],
    },
    {
      image: '',
      link: 'technologist-ai',
      title: 'AI / Deep Learning',
      description: '',
      component: [
        <TimeNetwork
          height={height}
          width={width}
          data={timedata}
          colorOne={'orange'}
          colorTwo={'red'}
          radius={3}
          timeStep={50}
        />,
      ],
    },
    {
      image: '',
      link: 'technologist-viz',
      title: 'Dashboards',
      description: '',
      component: [
        <TimeNetwork
          height={height}
          width={width}
          data={timedata}
          colorOne={'white'}
          colorTwo={'purple'}
          radius={4}
          timeStep={200}
        />,
      ],
    },
  ]
  useEffect(() => {}, [])
  return (
    <>
      <SEO title='Travel Agency' />
      <main className='page-wrapper'>
        <HeaderOne btnStyle='btn-small' HeaderSTyle='header-not-transparent' />
        <Separator />

        <div className=''>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Hire Me'
                  title='Portfolio'
                  description=''
                />
              </div>
            </div>
            <div className='row row--15'>
              {BannerData.map((item) => (
                <div
                  key={item.id}
                  className='col-lg-4 col-md-6 col-sm-12 col-12 mt--30'
                >
                  <BlogItem StyleVar='box-card-style-default' data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Separator />
        {/* <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Hire Me'
                  title=''
                  description='Looking for a data scientist or NLP engineer?'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <Competencies />
              </div>
            </div>
          </div>
        </div> */}

        {/* <Separator />
        <Copyright /> */}
      </main>
    </>
  )
}

export default PortfolioMain
