import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import classes from './Dashboard.module.css'
import debounce from 'lodash/debounce'

const BarChart = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null)
  const containerRef = useRef(null)
  const barColor = '#69b3a2'
  const margin = { top: 90, right: 50, bottom: 50, left: 50 },
    width = (props.width ? props.width : 600) - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom

  useEffect(() => {
    let data = props.data
    const svgEl = d3.select(svgRef.current)
    svgEl.selectAll('*').remove()
    const svg = svgEl
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(data.map((d) => d.x))
      .padding(0.2)
    svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(xScale))
      .selectAll('text')
      //.attr('transform', 'translate(-10,0)rotate(-45)')
      .style('text-anchor', 'end')

    const yScale = d3.scaleLinear().domain([0, 1]).range([height, 0])
    svg.append('g').call(d3.axisLeft(yScale))

    svg
      .selectAll('mybar')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d) => xScale(d.x))
      .attr('width', xScale.bandwidth())
      .attr('fill', barColor)
      .attr('height', (d) => height - yScale(0)) // always equal to 0
      .attr('y', (d) => yScale(0))

    svg
      .selectAll('rect')
      .transition()
      .duration(800)
      .attr('y', (d) => yScale(d.y))
      .attr('height', (d) => height - yScale(d.y))
      .delay((d, i) => i * 100)
  }, [props.data, props.height, props.width])

  return (
    <div
      id='chartContainer'
      className={classes.chartContainer}
      ref={containerRef}
    >
      <div id='mybar'>
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>
    </div>
  )
}

export default BarChart
