import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Layout from '../common/Layout'
import { FaSistrix } from 'react-icons/fa'
import TimeSeries from '../dashboard/TimeSeries'
import BarChart from '../dashboard/BarChart'
import Heatmap from '../dashboard/Heatmap'
import CircularBarchart from '../dashboard/CircularBarchart'
import CountryList from '../data/dashboard/CountryList.json'
import createDummyBarChartData from '../datafunctions/createDummyBarchartData'
import BarchartInteractive from '../dashboard/BarChartInteract'
import createDataTimeseries from '../datafunctions/createDataTimeseries'
import CalendarHeatmap from '../dashboard/CalendarHeatmap'
import DashboardHeader from '../mycomponents/DashboardHeader'

const TestPage = () => {
  const ref = useRef(null)
  const refHalf = useRef(null)
  const refThird = useRef(null)
  const refQuarter = useRef(null)
  const refSixth = useRef(null)

  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [widthHalf, setWidthHalf] = useState(0)
  const [widthThird, setWidthThird] = useState(0)
  const [widthQuarter, setWidthQuarter] = useState(0)
  const [widthSixth, setWidthSixth] = useState(0)

  const [dummyBarChart, setDummyBarChart] = useState([])
  const [dummyTimeSeries, setDummyTimeSeries] = useState([])

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth)
    setWidthHalf(refHalf.current.offsetWidth)
    setWidthThird(refThird.current.offsetWidth)
    setWidthQuarter(refQuarter.current.offsetWidth)
    setWidthSixth(refSixth.current.offsetWidth)

    setHeight(ref.current.offsetHeight)
  }, [])

  const randomData = () => {
    setDummyBarChart(createDummyBarChartData())
    setDummyTimeSeries(createDataTimeseries())
  }

  useEffect(() => {
    console.log(dummyTimeSeries)
  }, [dummyTimeSeries])

  const eventListener = () => {
    if (document.getElementById('chartContainerFull').clientWidth != null) {
      setWidthHalf(document.getElementById('chartContainerHalf').clientWidth)
      setWidthThird(document.getElementById('chartContainerThird').clientWidth)
      setWidthQuarter(
        document.getElementById('chartContainerQuarter').clientWidth
      )
      setWidthSixth(document.getElementById('chartContainerSixth').clientWidth)
      setWidth(document.getElementById('chartContainerFull').clientWidth)
      setHeight(document.getElementById('chartContainerFull').clientHeight)
    }
    console.log(width)
  }

  useEffect(() => {
    window.addEventListener('resize', eventListener)
    randomData()
    return () => {
      window.removeEventListener('resize', eventListener)
    }
  }, [width])

  return (
    <Layout>
      <DashboardHeader
        title='Sample Economics Dashboard'
        rootUrl='/'
        parentUrl='Home'
        currentUrl='Advance Tab'
        description='In business computer information systems, a dashboard is a type of graphical user interface which often provides at-a-glance views of key performance indicators (KPIs) relevant to a particular objective or business process. In other usage, "dashboard" is another name for "progress report" or "report" and considered a form of data visualization. In providing this overview, business owners can save time and improve their decision making by utilizing dashboards.'
      />
      <div>
        <div className='container'>
          {/* 1/2-WIDTH VIZ */}
          <div className='col-lg-12'>
            <div className='row mb--10'>
              <div className='col-lg-6' ref={refHalf}>
                <div className='inner' id='chartContainerHalf'>
                  <div>
                    <BarchartInteractive
                      height={height}
                      width={widthHalf}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthHalf}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 1/6-WIDTH VIZ */}
          <div className='col-lg-12'>
            <div className='row mb--10'>
              <div className='col-lg-2' ref={refSixth}>
                <div className='inner' id='chartContainerSixth'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthSixth}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 1/3-WIDTH VIZ */}
          <div className='col-lg-12'>
            <div className='row mb--10'>
              <div className='col-lg-4' ref={refThird}>
                <div className='inner' id='chartContainerThird'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthThird}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthThird}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthThird}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FULL-WIDTH VIZ */}

          <div className='col-lg-12'>
            <div className='row mb--10'>
              <div className='col-lg-12' ref={ref}>
                <div className='inner' id='chartContainerFull'>
                  <div>
                    <CalendarHeatmap
                      height={height}
                      width={width}
                      data={dummyTimeSeries}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 1/4-WIDTH VIZ */}

          <div className='col-lg-12'>
            <div className='row mb--10'>
              <div className='col-lg-3' ref={refQuarter}>
                <div className='inner' id='chartContainerQuarter'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthQuarter}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthQuarter}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthQuarter}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='inner'>
                  <div>
                    <BarChart
                      height={height}
                      width={widthQuarter}
                      data={dummyBarChart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='view-more-button'>
            <a className='btn-default' onClick={randomData}>
              Randomize Data
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TestPage
