import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from 'react-router-dom'
import TabPaneler from './TabPaneler'

const dataList = [
  {
    title: 'EdTech Dashboards',
    description: 'Checkout this example of a dashboard for education data.',
    link: 'dashboard-ed',
  },
  {
    title: 'Business Dashboards',
    description: 'Checkout this example of a dashboard for economic data.',
    link: 'dashboard-econ',
  },
]

const dataList2 = [
  {
    title: 'Country Dashboard',
    description: 'Checkout this example of a dashboard for geo-data.',
    link: 'dashboard-country',
  },
  {
    title: 'Health Crisis Dashboard',
    description: 'Checkout this example of a dashboard for health crisis data.',
    link: 'dashboard-health',
  },
]

const DevTab = () => {
  return (
    <div>
      <div id='tabrow' className='row'>
        <div className='col-lg-12'>
          <Tabs>
            <div className='row row--30 align-items-center'>
              <div className='col-lg-12'>
                <div className='rn-default-tab style-three'>
                  <div className='tab-button-panel'>
                    <TabList className='tab-button'>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Dashboards</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Geodata</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className='tab-content-panel'>
                    <TabPanel>
                      <TabPaneler
                        title='Dynamic Visuals'
                        description="Communicating data is just as important as the
                                  data itself. That's why I'm always exploring
                                  the different approaches to visualizing data.
                                  I use the D3 library and React.js to create
                                  dashboards and visualizations for a variety of
                                  use cases."
                        dataList={dataList}
                        codeButton={true}
                      />
                    </TabPanel>

                    <TabPanel>
                      <TabPaneler
                        title='Data and Our World'
                        description='Geodata is particularly interesting to explore.'
                        dataList={dataList2}
                        codeButton={true}
                      />
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default DevTab
