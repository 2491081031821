import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import { color } from "d3";

const Treemap = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const margin = { top: 20, right: 20, bottom: 20, left: 20 },
    width = props.width - margin.left - margin.right,
    height = props.width - margin.top - margin.bottom,
    fontSize = props.width > 400 ? "10px" : "6px",
    fontSizeLarge = props.width > 400 ? "20px" : "10px";

  const cache = useRef(props.data);

  let data = props.data[0];
  useEffect(() => {
    const root = d3.hierarchy(data).sum(function (d) {
      return d.value;
    }); // Here the size of each leave is given in the 'value' field in input data

    d3
      .treemap()
      .size([width, height])
      .paddingTop(30)
      .paddingRight(7)
      .paddingInner(3)(
      // Padding between each rectangle
      //.paddingOuter(6)
      //.padding(20)
      root
    );
    console.log(data);
    const countries = [];
    data.children.forEach((d, idx) => {
      countries[idx] = d.name;
    });
    const color = d3
      .scaleOrdinal()
      .domain(countries)
      .range(["orange", "#D18975", "#8FD175", "#D18975", "#8FD175"]);

    const opacity = d3.scaleLinear().domain([0, 200]).range([0.5, 1]);

    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const svg = svgEl
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    svg
      .selectAll("rect")
      .data(root.leaves())
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return d.x0;
      })
      .attr("y", function (d) {
        return d.y0;
      })
      .attr("width", function (d) {
        return d.x1 - d.x0;
      })
      .attr("height", function (d) {
        return d.y1 - d.y0;
      })
      .style("stroke", "black")
      .style("fill", function (d) {
        return color(d.data.name);
      })
      .style("opacity", function (d) {
        return opacity(d.data.value);
      });

    // and to add the text labels
    svg
      .selectAll("text")
      .data(root.leaves())
      .enter()
      .append("text")
      .attr("x", function (d) {
        return d.x0 + 5;
      }) // +10 to adjust position (more right)
      .attr("y", function (d) {
        return d.y0 + 20;
      }) // +20 to adjust position (lower)
      .text(function (d) {
        return d.data.name;
      })
      .attr("font-size", fontSize)
      .attr("fill", "white");

    // and to add the text labels
    svg
      .selectAll("vals")
      .data(root.leaves())
      .enter()
      .append("text")
      .attr("x", function (d) {
        return d.x0 + 5;
      }) // +10 to adjust position (more right)
      .attr("y", function (d) {
        return d.y0 + 35;
      }) // +20 to adjust position (lower)
      .text(function (d) {
        return d.data.value;
      })
      .attr("font-size", fontSize)
      .attr("fill", "white");

    // Add title for the 3 groups
    svg
      .selectAll("titles")
      .data(
        root.descendants().filter(function (d) {
          return d.depth == 1;
        })
      )
      .enter()
      .append("text")
      .attr("x", function (d) {
        return d.x0;
      })
      .attr("y", function (d) {
        return d.y0 + 21;
      })
      .text(function (d) {
        return d.data.name;
      })
      .attr("font-size", fontSizeLarge)
      .attr("fill", function (d) {
        return color(d.data.name);
      });

    // Add title for the 3 groups
    svg
      .append("text")
      .attr("x", 0)
      .attr("y", 14) // +20 to adjust position (lower)
      .text("")
      .attr("font-size", fontSizeLarge)
      .attr("fill", "grey");
  }, [data, props.width]);

  return (
    <div>
      <div className={classes.treemapContainer} ref={containerRef} id="Treemap">
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>
    </div>
  );
};

export default Treemap;
