import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";

const ZoomableIcicle = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);

  const margin = { top: 10, right: 10, bottom: 10, left: 10 },
    width = props.width > 1000 ? 700 : props.width - margin.left - margin.right,
    height =
      props.width > 1000 ? 700 : props.width - margin.top - margin.bottom,
    fontSize = props.width > 400 ? "10px" : "6px",
    fontSizeLarge = props.width > 400 ? "30px" : "10px",
    padding = 10,
    diameter = width;

  useEffect(() => {
    let data = props.data;
    let format = d3.format(",d");

    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    // const color = d3
    //   .scaleLinear()
    //   .domain([0, 5])
    //   .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
    //   .interpolate(d3.interpolateHcl);
    // const color = d3.scaleSequential([8, 0], d3.interpolateMagma);
    const color = d3.scaleOrdinal(
      d3.quantize(d3.interpolateRainbow, data.children.length + 1)
    );
    // const pack = (data) =>
    //   d3.pack().size([width, height]).padding(3)(
    //     d3
    //       .hierarchy(data)
    //       .sum((d) => d.value)
    //       .sort((a, b) => b.value - a.value)
    //   );

    const partition = (data) => {
      const root = d3
        .hierarchy(data)
        .sum((d) => d.value)
        .sort((a, b) => b.height - a.height || b.value - a.value);
      return d3.partition().size([height, ((root.height + 1) * width) / 3])(
        root
      );
    };

    const root = partition(data);

    console.log(root);
    let focus = root;

    const svg = svgEl
      .append("svg")
      .attr("viewBox", [0, 0, width, height])
      .style("font", "10px sans-serif");

    const cell = svg
      .selectAll("g")
      .data(root.descendants())
      .join("g")
      .attr("transform", (d) => `translate(${d.y0},${d.x0})`);

    const rect = cell
      .append("rect")
      .attr("width", (d) => d.y1 - d.y0 - 1)
      .attr("height", (d) => rectHeight(d))
      .attr("fill-opacity", 0.6)
      .attr("fill", (d) => {
        if (!d.depth) return "#ccc";
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .style("cursor", "pointer")
      .on("click", clicked);

    const text = cell
      .append("text")
      .style("user-select", "none")
      .attr("pointer-events", "none")
      .attr("x", 4)
      .attr("y", 13)
      .attr("fill", "white")
      .attr("fill-opacity", (d) => +labelVisible(d));

    text.append("tspan").text((d) => d.data.name);

    const tspan = text
      .append("tspan")
      .attr("fill-opacity", (d) => labelVisible(d) * 0.7)
      .text((d) => ` ${format(d.value)}`);

    cell.append("title").text(
      (d) =>
        `${d
          .ancestors()
          .map((d) => d.data.name)
          .reverse()
          .join("/")}\n${format(d.value)}`
    );

    function clicked(event, p) {
      focus = focus === p ? (p = p.parent) : p;
      if (focus !== null) {
        root.each(
          (d) =>
            (d.target = {
              x0: ((d.x0 - p.x0) / (p.x1 - p.x0)) * height,
              x1: ((d.x1 - p.x0) / (p.x1 - p.x0)) * height,
              y0: d.y0 - p.y0,
              y1: d.y1 - p.y0,
            })
        );

        const t = cell
          .transition()
          .duration(750)
          .attr("transform", (d) => `translate(${d.target.y0},${d.target.x0})`);

        rect.transition(t).attr("height", (d) => rectHeight(d.target));
        text.transition(t).attr("fill-opacity", (d) => +labelVisible(d.target));
        tspan
          .transition(t)
          .attr("fill-opacity", (d) => labelVisible(d.target) * 0.7);
      }
    }

    function rectHeight(d) {
      return d.x1 - d.x0 - Math.min(1, (d.x1 - d.x0) / 2);
    }

    function labelVisible(d) {
      return d.y1 <= width && d.y0 >= 0 && d.x1 - d.x0 > 16;
    }
  }, [props.data, props.height, props.width]);

  return (
    <div>
      <div
        id="chartContainer"
        className={classes.zoomableIcicleContainer}
        ref={containerRef}
      >
        <div id="ZoomableIcicle">
          <svg
            width={width + margin.left + margin.right}
            height={height + margin.top + margin.bottom}
          >
            <g
              ref={svgRef}
              //   transform={`translate(${margin.left}, ${margin.top})`}
            />
          </svg>
        </div>
        <div className="row ml--10 mr--10 mb--5 mt--20">
          <Link className="btn-default btn-small mr--10 ml--10" to={`/`}>
            See Code
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ZoomableIcicle;
