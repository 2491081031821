const createDummyBarChartData = () => {
  const ARRAY_LENGTH = 10
  const randomArray = []
  for (let i = 0; i < ARRAY_LENGTH; i++) {
    randomArray.push(Math.random())
  }
  const listArray = Array.from(Array(10).keys())

  function func(arr1, arr2) {
    const obj = []
    arr1.forEach((Curr_element, index) => {
      obj[index] = { x: arr1[index], y: arr2[index] }
    })
    return obj
  }
  const jsondummy = func(listArray, randomArray)

  return jsondummy
}

export default createDummyBarChartData
