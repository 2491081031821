import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NetworkGraph from '../dashboard/StickyNetworkGraph'

const AccordionOne = (props, { customStyle }) => {
  console.log(props)
  // console.log(`${process.env.PUBLIC_URL}/${props[0].image}`)
  return (
    <div className='row mb--40'>
      <Accordion
        className={`rn-accordion-style ${customStyle}`}
        defaultActiveKey='0'
      >
        {props.data.map((data, index) => (
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant='link' eventKey={index + 1}>
                <div className='row mb--10'>
                  <div className='inner'>
                    <h4 className='title'>{data.title}</h4>
                    <p className='description'>
                      {data.subtitle}
                      <strong>{data.interaction}</strong>
                    </p>
                  </div>
                </div>
                {data.image ? (
                  <img src={`${data.image}`} alt='Blog Image' />
                ) : (
                  <></>
                )}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index + 1}>
              <Card.Body>
                {data.graph
                  ? [
                      <div className='tab-content'>
                        <div className='inner'>
                          <div className='thumbnail'>{data.graph}</div>
                        </div>
                      </div>,
                    ]
                  : [
                      <div className='card-box card-style-1 text-start'>
                        <div className='inner'>
                          <div className='image'>
                            <Link to={data.link}>
                              {/* <img
                              src={`${val.image}`}
                              alt="card Images"
                            /> */}
                            </Link>
                          </div>

                          <div>
                            <h4 className='title color-white mb--0'>
                              <Link
                                to={data.link}
                                dangerouslySetInnerHTML={{
                                  __html: data.title,
                                }}
                              ></Link>
                            </h4>
                          </div>
                          <div className='content'>
                            <p className='description b1 color-gray mb--0'>
                              <Link
                                to={data.link}
                                dangerouslySetInnerHTML={{
                                  __html: data.text,
                                }}
                              ></Link>
                            </p>
                          </div>
                          {/* <div className='col-lg-6'>
                              <div className='inner'>
                                <div>{data.minigraph}</div>
                              </div>
                            </div> */}
                        </div>
                      </div>,
                    ]}
                <div className='row ml--10 mr--10 mb--5 mt--10'>
                  <Link
                    className='btn-default btn-small mr--10 ml--10'
                    to={data.link}
                  >
                    {data.button}
                  </Link>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  )
}
export default AccordionOne
