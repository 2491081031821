import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";

const LanguageNetwork = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [nodesUnstick, setNodesUnstick] = useState(true);
  const [sizeInternet, setSizeInternet] = useState(true);
  const [buttonText, setButtonText] = useState("Display Number of Speakers");

  const margin = { top: 10, right: 10, bottom: 10, left: 10 },
    width = props.width - margin.left - margin.right,
    height =
      props.width > 1000 ? 700 : props.width - margin.top - margin.bottom;

  const gender = ["digital", "aural"];
  const types = ["other"];
  const color = d3.scaleOrdinal().domain(types).range(d3.schemePaired);
  const colorNode = d3.scaleOrdinal().domain(gender).range(d3.schemeAccent);
  const colorOverRep = d3
    .scaleOrdinal()
    .domain(["0", "1"])
    .range(d3.schemeCategory10);
  const colorScalePopulation = d3
    .scaleSequential()
    .interpolator(d3.interpolateRdYlBu)
    .domain([0, 1000]);

  const colorScaleSize = d3
    .scaleSequential()
    .interpolator(d3.interpolateOrRd)
    .domain([0, 50]);

  const unstickNodes = () => {
    setNodesUnstick(!nodesUnstick);
  };

  const showInternetData = () => {
    setSizeInternet(!sizeInternet);
    if (sizeInternet) {
      setButtonText("Display Size of Corpus");
    } else {
      setButtonText("Display Number of Speakers");
    }
  };

  useEffect(() => {
    let data = props.data;
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const links = data.links.map((d) => Object.create(d));
    const nodes = data.nodes.map((d) => Object.create(d));

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3.forceLink(links).id((d) => d.id)
      )
      .force("charge", d3.forceManyBody().strength(-width / 10))
      .force("x", d3.forceX())
      .force("y", d3.forceY())
      //   .force("center", d3.forceCenter(width / 2, height / 2))
      .on("tick", tick);

    const linkArc = (d) => {
      const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
      return `
          M${d.source.x},${d.source.y}
          A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
        `;
    };

    const svg = svgEl
      .append("svg")
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    svg
      .append("defs")
      .selectAll("marker")
      .data(types)
      .join("marker")
      .attr("id", (d) => `arrow-${d}`)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 15)
      .attr("refY", -0.5)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      // .attr('fill', color)
      .attr("d", "M0,-5L10,0L0,5");

    const link = svg
      .append("g")
      .attr("fill", "none")

      .selectAll("path")
      .data(links)
      .classed("link", true)
      .join("path")
      .attr("stroke", "pink")
      // .attr('stroke', (d) => colorNode(d.type))

      .attr("marker-end", (d) => ``);

    const node = svg
      .append("g")
      .attr("fill", "currentColor")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .selectAll("g")
      .data(nodes)
      .join("g");

    link.attr("stroke-width", (d) => 1);
    node
      .append("circle")
      .attr("stroke", "white")
      .attr("stroke-width", (d) => (d.empire === "1" ? 2 : 0))
      .attr("stroke", (d) => (d.empire === "1" ? "purple" : "none"))
      .attr(
        "fill",
        sizeInternet
          ? (d) => colorOverRep(d.overrep)
          : (d) => colorOverRep(d.overrep)
      )
      .attr("opacity", 0.9)
      .attr(
        "r",
        sizeInternet
          ? (d) => d.size * (props.width / 200)
          : (d) => d.speakers / (props.width / 20)
      )
      .classed("node", true)
      .classed("fixed", (d) => d.fx !== undefined);

    node
      .append("text")
      .attr("x", 8)
      .attr("y", "0.31em")
      .text((d) => d.id)
      .attr("font-size", "10px")
      .attr("fill", "white")
      .clone(true)
      .lower()
      .attr("x", 7)
      .attr("y", "0.31em")
      .attr("font-size", "10.5px")
      .attr("fill", "green");

    // .attr('stroke', 'white')
    // .attr('stroke-width', '1.5')

    simulation.on("tick", () => {
      link.attr("d", linkArc);
      node.attr("transform", (d) => `translate(${d.x},${d.y})`);
    });

    //// functions
    function intern(value) {
      return value !== null && typeof value === "object"
        ? value.valueOf()
        : value;
    }

    function clamp(x, lo, hi) {
      return x < lo ? lo : x > hi ? hi : x;
    }

    const drag = d3.drag().on("start", dragstart).on("drag", dragged);

    node.call(drag).on("click", click);

    function tick() {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);
      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      // text
      //   .attr('x', (d) => d.x - 15) //position of the lower left point of the text
      //   .attr('y', (d) => d.y + 15) //position of the lower left point of the text
    }

    function click(event, d) {
      console.log("clicked");
      delete d.fx;
      delete d.fy;
      d3.select(this).attr("stroke", "transparent");
      d3.select(this).classed("fixed", false);
      simulation.alpha(1).restart();
    }

    function dragstart() {
      // console.log('dragstart')
      d3.select(this).classed("fixed", true);
    }

    function dragged(event, d) {
      // console.log('dragged')
      d.fx = event.x; //clamp(event.x, event.x, width);
      // console.log('dragstart')
      d.fy = event.y; //clamp(event.y, event.y, height);
      d3.select(this).attr("stroke", "pink");
      d3.select(this).attr("stroke-width", 2);
      simulation.alpha(1).restart();
    }
  }, [props.data, props.height, props.width, nodesUnstick, sizeInternet]);

  return (
    <div
      id="chartContainer"
      className={classes.networkContainer}
      ref={containerRef}
    >
      <div id="LanguageNetwork">
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>

      <div className="row ml--10 mr--10 mb--5 mt--10">
        <p>{props.undertitle}</p>
      </div>
      <div className="row ml--10 mr--10 mb--5 mt--10">
        <button
          className="btn-default btn-small mr--10 ml--10"
          onClick={unstickNodes}
        >
          Unstick Nodes
        </button>
        <button
          className="btn-default btn-small mr--10 ml--10"
          onClick={showInternetData}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default LanguageNetwork;
