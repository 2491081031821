import React, { useState } from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import HeaderTopNews from "../common/header/HeaderTopNews";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import { FiArrowRight } from "react-icons/fi";
import Separator from "../elements/separator/Separator";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import AboutTwo from "../elements/about/AboutTwo";
import AboutThree from "../mycomponents/AboutThree";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix";
import PricingFive from "../elements/pricing/PricingFive";
import BrandOne from "../elements/brand/BrandOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimeNetwork from "../dashboard/TimeNetwork";
import timedata from "../data/dashboard/sfhh@4.json";
import BlogItem from "../mycomponents/BlogItem";
const SeoAgency = () => {
  const [height, setHeight] = useState(document.documentElement.clientHeight);
  const [width, setWidth] = useState(
    document.documentElement.clientWidth -
      Math.round(document.documentElement.clientWidth * 0.25)
  );

  const timeNetwork = [
    <TimeNetwork
      height={height}
      width={width}
      data={timedata}
      colorOne={"white"}
      colorTwo={"black"}
      radius={4}
      timeStep={300}
    />,
  ];

  const BannerData = [
    {
      image: "",
      link: "technologist-overview",
      title: "Technologist",
      description: "",
      component: [],
    },
    {
      image: "",
      link: "educator",
      title: "Educator",
      description: "",
      component: [],
    },
    {
      image: "",
      link: "photography",
      title: "Photography",
      description: "",
      component: [],
    },
  ];

  return (
    <>
      <SEO title="About" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-6 shape-right height-650">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="title color-white">
                    Hello! <br />
                    I'm Sean.
                  </h1>
                  <h4 class="subtitle rn-sub-badge">
                    <span class="theme-gradient">
                      Data Scientist / Educator / Language Nerd
                    </span>
                  </h4>
                </div>
              </div>

              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail">
                  <img
                    src="./myphotos/seanifer2.png"
                    alt="Banner Images"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <div className="container">
          <div className="row row--15">
            {BannerData.map((item) => (
              <div
                key={item.id}
                className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
              >
                <BlogItem StyleVar="box-card-style-default" data={item} />
              </div>
            ))}
          </div>
        </div>
        <Separator />
        <AboutThree />

        <div className="rwt-timeline-area rn-section-gap"></div>
        <Copyright />
      </main>
    </>
  );
};

export default SeoAgency;
