import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";

const CirclePack = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const tooltipRef = useRef();

  const margin = { top: 10, right: 10, bottom: 10, left: 10 },
    width = props.width > 1000 ? 700 : props.width - margin.left - margin.right,
    height =
      props.width > 1000 ? 700 : props.width - margin.top - margin.bottom,
    fontSize =
      props.width > 600
        ? 20
        : (props.width < 600) & (props.width > 400)
        ? 10
        : 6,
    fontSizeLarge = props.width > 400 ? "30px" : "10px",
    padding = 10,
    diameter = width;

  useEffect(() => {
    let data = props.data;

    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    // const color = d3
    //   .scaleLinear()
    //   .domain([0, 5])
    //   .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
    //   .interpolate(d3.interpolateHcl);
    const color = d3.scaleSequential([8, 0], d3.interpolateMagma);

    const pack = (data) =>
      d3.pack().size([width, height]).padding(3)(
        d3
          .hierarchy(data)
          .sum((d) => d.value)
          .sort((a, b) => b.value - a.value)
      );

    const svg = svgEl
      .append("svg")
      .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
      .style("display", "block")
      .style("margin", "0 -14px")
      .style("background", color(0))
      .style("cursor", "pointer")
      .on("click", (event) => zoom(event, root));

    const root = pack(data);

    const zoomTo = (v) => {
      const k = width / v[2];
      view = v;
      label.attr(
        "transform",
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
      );
      node.attr(
        "transform",
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
      );
      node.attr("r", (d) => d.r * k);
    };

    const zoom = (event, d) => {
      const focus0 = focus;
      focus = d;
      const transition = svg
        .transition()
        .duration(event.altKey ? 7500 : 750)
        .tween("zoom", (d) => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
          return (t) => zoomTo(i(t));
        });
      label
        .filter(function (d) {
          return d.parent === focus || this.style.display === "inline";
        })
        .transition(transition)
        .style("fill-opacity", (d) => (d.parent === focus ? 1 : 0))
        .on("start", function (d) {
          if (d.parent === focus) this.style.display = "inline";
        })
        .on("end", function (d) {
          if (d.parent !== focus) this.style.display = "none";
        });
    };

    const mouseover = function (event, d) {
      const tooltipDiv = tooltipRef.current;
      if (tooltipDiv) {
        d3.select(tooltipDiv).transition().duration(200).style("opacity", 0.9);
        d3.select(tooltipDiv)
          .attr("test", console.log(event))
          .html(`${event.target.__data__}"`)
          // TODO: some logic when the tooltip could go out from container
          .style("left", event.layerX + "px")
          .style("top", event.layerY - 28 + "px");
      }
    };

    const mouseout = () => {
      const tooltipDiv = tooltipRef.current;
      if (tooltipDiv) {
        d3.select(tooltipDiv).transition().duration(500).style("opacity", 0);
      }
    };

    let focus = root;
    let view;

    const node = svg
      .append("g")
      .selectAll("circle")
      .data(root.descendants().slice(1))
      .join("circle")
      .attr("fill", (d) => (d.children ? color(d.depth) : "white"))
      .style("opacity", 0.6)
      .attr("pointer-events", (d) => (!d.children ? "none" : null))
      .on("mouseover", function () {
        d3.select(this).attr("stroke", "#000");
      })
      .on("mouseout", function () {
        d3.select(this).attr("stroke", null);
      })
      .on(
        "click",
        (event, d) => focus !== d && (zoom(event, d), event.stopPropagation())
      )
      .on("mouseover", mouseover)
      // .on("mousemove", mousemove)
      .on("mouseleave", mouseout);

    const label = svg
      .append("g")
      .style("font", `georgia sans-serif`)
      // .style("font", `${fontSize} georgia sans-serif`)
      // .style("font-size", function (d) {
      //   return d.count + "px";
      // })
      .style("font-weight", function (d, i) {
        return i * 100 + 500;
      })
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(root.descendants())
      .join("text")
      .style(
        "font-size",
        (d) => (d.data.value < 5 ? d.data.value : d.data.value / 40 + 5)
        // ? d.data.value - 10
        // : 20
      )
      .style("fill-opacity", (d) => (d.parent === root ? 1 : 0))
      .style("display", (d) => (d.parent === root ? "inline" : "none"))
      .text((d) => d.data.name);

    const circle = node
      .append("circle")
      .attr("r", (d) => d.r)
      .attr("stroke-width", (d) => 1 / (d.depth + 1))
      .attr("fill", (d) => color(d.height))
      .on("mouseover", function () {
        d3.select(this).attr("stroke", "#000");
      })
      .on("mouseout", function () {
        d3.select(this).attr("stroke", null);
      });

    zoomTo([root.x, root.y, root.r * 2]);

    // circle.on("click", (d) => {
    //   svg
    //     .transition()
    //     .duration(1000)
    //     .attr("fake", (d) => console.log(d));
    //   // .attr("viewBox", [
    //   //   d.x - d.r - padding,
    //   //   d.y - d.r - padding,
    //   //   d.r * 2 + padding * 2,
    //   //   d.r * 2 + padding * 2,
    //   // ]);
    // });
  }, [props.data, props.height, props.width]);

  return (
    <div>
      <div
        id="chartContainer"
        className={classes.circlePackContainer}
        ref={containerRef}
      >
        <div id="CirclePack">
          <svg
            width={width + margin.left + margin.right}
            height={height + margin.top + margin.bottom}
          >
            <g
              ref={svgRef}
              // transform={`translate(${margin.left}, ${margin.top})`}
            />
          </svg>
          {/* <button className={classes.tooltip} ref={tooltipRef}></button> */}
        </div>
      </div>
    </div>
  );
};

export default CirclePack;
