import React from 'react'
import { Link } from 'react-router-dom'
import { FiArrowRight } from 'react-icons/fi'
import AccordionOne from './AccordionOne'
import ScrollAnimation from 'react-animate-on-scroll'

const AboutThree = () => {
  return (
    <div className='rn-about-area about-style-3 rn-section-gap'>
      <div className='container'>
        <div className='row row--30'>
          <div className='col-lg-12 mt_md--10 mt_sm--10'>
            <div className='inner'>
              {/* <div className='section-title'>
                <h4 className='subtitle'>
                  <span className='theme-gradient'>Hire Me</span>
                </h4>
                <h2 className='title mb--20'>How can I help?</h2>
              </div> */}
              <AccordionOne customStyle='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutThree
