import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import classes from "./Dashboard.module.css";
import debounce from "lodash/debounce";

const BubbleChart = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const tooltipRef = useRef();

  const margin = { top: 50, right: 50, bottom: 50, left: 50 },
    width = props.width - margin.left - margin.right,
    height = props.width - margin.top - margin.bottom,
    divisor = Math.round(props.width / 100),
    fontSize =
      props.width > 600
        ? 20
        : (props.width < 600) & (props.width > 400)
        ? 10
        : 6,
    fontSizeLarge = props.width > 400 ? "30px" : "10px",
    diameter = width,
    value = (d) => d.count,
    group = (d) => d.type,
    label = (d) => d.source,
    title = (d) => d.source,
    font_size = (d) => d.font_size,
    link = (d) => `https://en.wiktionary.org/wiki/${d.source}`,
    colors = d3.schemeTableau10, // an array of colors (for groups)
    fill = "#ccc", // a static fill color, if no group channel is specified
    fillOpacity = 0.7, // the fill opacity of the bubbles
    stroke = 2, // a static stroke around the bubbles
    strokeWidth = 2, // the stroke width around the bubbles, if any
    strokeOpacity = 1,
    padding = 10,
    linkTarget = "_blank"; // the stroke opacity around the bubbles, if any

  useEffect(() => {
    let data = props.data.links;
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const D = d3.map(data, (d) => d);
    const V = d3.map(data, value);
    const F = d3.map(data, font_size);
    const G = group == null ? null : d3.map(data, group);
    const I = d3.range(V.length).filter((i) => V[i] > 0);

    // const groups = I.map((i) => G[i])
    const groups = G && new d3.InternSet(I.map((i) => G[i]));

    const color = G && d3.scaleOrdinal(groups, colors);

    const L = label == null ? null : d3.map(data, label);
    const T =
      title === undefined ? L : title == null ? null : d3.map(data, title);

    const mouseover = function (event, d) {
      const tooltipDiv = tooltipRef.current;
      if (tooltipDiv) {
        d3.select(tooltipDiv).transition().duration(200).style("opacity", 0.9);
        d3.select(tooltipDiv)

          .html(`${event.target.__data__}`)
          // TODO: some logic when the tooltip could go out from container
          .style("left", event.layerX + "px")
          .style("top", event.layerY - 28 + "px");
      }
      // console.log(event)
    };

    const mouseout = () => {
      const tooltipDiv = tooltipRef.current;
      if (tooltipDiv) {
        d3.select(tooltipDiv).transition().duration(500).style("opacity", 0);
      }
    };
    const root = d3
      .pack()
      .size([
        width - margin.left - margin.right,
        width - margin.top - margin.bottom,
      ])
      .padding(padding)(d3.hierarchy({ children: I }).sum((i) => V[i]));

    const svg = svgEl
      .append("g")
      .attr("transform", `translate(${width / divisor},${width / divisor})`)
      // .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
      .style("display", "block")
      .attr("fill", "white")

      .attr("font-family", "sans-serif")
      .attr("text-anchor", "middle");

    const leaf = svg
      .selectAll("a")
      .data(root.leaves())
      .join("a")
      .attr(
        "xlink:href",
        link == null ? null : (d, i) => link(D[d.data], i, data)
      )
      .attr("target", link == null ? null : linkTarget)
      .attr("transform", (d) => `translate(${d.x},${d.y})`);

    leaf
      .append("circle")
      .attr("stroke", stroke)
      .attr("stroke-width", strokeWidth)
      .attr("stroke-opacity", strokeOpacity)

      .attr("fill", G ? (d) => color(G[d.data]) : fill == null ? "none" : fill)
      .attr("fill-opacity", fillOpacity)
      .attr("r", (d) => d.r);

    if (T) leaf.append("title").text((d) => T[d.data]);

    if (L) {
      // A unique identifier for clip paths (to avoid conflicts).
      const uid = `O-${Math.random().toString(16).slice(2)}`;

      leaf
        .append("clipPath")
        .attr("id", (d) => `${uid}-clip-${d.data}`)
        .append("circle")
        .attr("r", (d) => d.r);

      leaf
        .append("text")
        .attr("font-size", (d) => F[d.data] * 1.5)
        // .attr('clip-path', (d) => `url(${new URL(`#${uid}-clip-${d.data}`)})`)
        .selectAll("tspan")
        .data((d) => `${L[d.data]}`.split(/\n/g))
        .join("tspan")
        .attr("x", 0)
        .attr("y", (d, i, D) => `${i - D.length / 2 + 0.85}em`)
        .attr("fill-opacity", (d, i, D) => (i === D.length - 1 ? 0.7 : null))
        .text((d) => d)
        .on("mouseover", mouseover)
        // .on("mousemove", mousemove)
        .on("mouseleave", mouseout);
    }
  }, [props.data, props.height, props.width]);

  return (
    <div
      id="chartContainer"
      className={classes.bubbleChartContainer}
      ref={containerRef}
    >
      <div id="BubbleChart">
        <svg
          width={width + margin.left + margin.right}
          height={width + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${width / 2}, ${width / 2})`}
          />
        </svg>
        <button className={classes.tooltip} ref={tooltipRef}></button>
      </div>
    </div>
  );
};

export default BubbleChart;
