import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import classes from './Dashboard.module.css'
import debounce from 'lodash/debounce'

const CalendarHeatmap = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null)
  const containerRef = useRef(null)
  const tooltipRef = useRef()

  const margin = { top: 30, right: 30, bottom: 30, left: 30 },
    width = props.width - margin.left - margin.right,
    // height = newHeight - margin.top - margin.bottom,
    // given d in data, returns the (quantitative) y-value
    title = null, // given d in data, returns the title text
    cellSize = width / 60,
    height = cellSize * 7, // width and height of an individual day, in pixels
    weekday = 'monday', // either: weekday, sunday, or monday
    formatDay = (i) => 'SMTWTFS'[i], // given a day number in [0, 6], the day-of-week label
    // format specifier string for months (above the chart)
    yFormat = null, // format specifier string for values (in the title)
    colors = d3.interpolatePiYG

  useEffect(() => {
    let data = props.data
    const X = d3.map(data, (d) => d.Date)
    const Y = d3.map(data, (d) => d.Volume)
    const I = d3.range(X.length)
    const countDay = weekday === 'sunday' ? (i) => i : (i) => (i + 6) % 7
    const timeWeek = weekday === 'sunday' ? d3.utcSunday : d3.utcMonday
    const weekDays = weekday === 'weekday' ? 5 : 7
    const height = cellSize * (weekDays + 2)
    const max = d3.quantile(Y, 0.9975, Math.abs)
    const color = d3.scaleSequential([-max, +max], colors).unknown('none')
    const formatMonth = d3.utcFormat('%b')

    const mouseover = function (event, d) {
      const tooltipDiv = tooltipRef.current
      if (tooltipDiv) {
        d3.select(tooltipDiv).transition().duration(200).style('opacity', 0.9)
        d3.select(tooltipDiv)
          .html(
            `${X[event.target.__data__]} instances of ${
              Y[event.target.__data__]
            }${event.target.__data__.y}"`
          )
          // TODO: some logic when the tooltip could go out from container
          .style('left', event.pageX + 'px')
          .style('top', event.pageY - 28 + 'px')
        // console.log(event)
      }
    }

    const mouseout = () => {
      const tooltipDiv = tooltipRef.current
      if (tooltipDiv) {
        d3.select(tooltipDiv).transition().duration(500).style('opacity', 0)
      }
    }
    // Compute titles.
    if (title === undefined) {
      const formatDate = d3.utcFormat('%B %-d, %Y')
      const formatValue = color.tickFormat(100, yFormat)
      title = (i) => `${formatDate(X[i])}\n${formatValue(Y[i])}`
    } else if (title !== null) {
      const T = d3.map(data, title)
      title = (i) => T[i]
    }

    const years = d3.groups(I, (i) => X[i].getUTCFullYear()).reverse()

    function pathMonth(t) {
      const d = Math.max(0, Math.min(weekDays, countDay(t.getUTCDay())))
      const w = timeWeek.count(d3.utcYear(t), t)
      return `${
        d === 0
          ? `M${w * cellSize},0`
          : d === weekDays
          ? `M${(w + 1) * cellSize},0`
          : `M${(w + 1) * cellSize},0V${d * cellSize}H${w * cellSize}`
      }V${weekDays * cellSize}`
    }

    const svgEl = d3.select(svgRef.current)
    svgEl.selectAll('*').remove()

    const svg = svgEl
      .append('g')
      .attr('width', width)
      .attr('height', height) // height * years.length
      .attr('viewBox', [0, 0, width, height]) // height * years.length
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 10)
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const year = svg
      .selectAll('g')
      .data(years)
      .join('g')
      .attr(
        'transform',
        (d, i) => `translate(40.5,${height * i + cellSize * 1.5})`
      )

    year
      .append('text')
      .attr('x', -5)
      .attr('y', -5)
      .attr('fill', 'white')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'end')
      .text(([key]) => key)

    year
      .append('g')
      .attr('text-anchor', 'end')
      .attr('fill', 'white')
      .selectAll('text')
      .data(weekday === 'weekday' ? d3.range(1, 6) : d3.range(7))
      .join('text')
      .attr('x', -5)
      .attr('y', (i) => (countDay(i) + 0.5) * cellSize)
      .attr('dy', '0.31em')
      .text(formatDay)

    const cell = year
      .append('g')
      .selectAll('rect')
      .data(
        weekday === 'weekday'
          ? ([, I]) => I.filter((i) => ![0, 6].includes(X[i].getUTCDay()))
          : ([, I]) => I
      )
      .join('rect')
      .attr('width', cellSize - 1)
      .attr('height', cellSize - 1)
      .attr('x', (i) => timeWeek.count(d3.utcYear(X[i]), X[i]) * cellSize + 0.5)
      .attr('y', (i) => countDay(X[i].getUTCDay()) * cellSize + 0.5)
      .attr('fill', (i) => color(Y[i]))
      .on('mouseover', mouseover)
      // .on("mousemove", mousemove)
      .on('mouseleave', mouseout)

    if (title) cell.append('title').text(title)

    const month = year
      .append('g')
      .selectAll('g')
      .data(([, I]) => d3.utcMonths(d3.utcMonth(X[I[0]]), X[I[I.length - 1]]))
      .join('g')

    month
      .filter((d, i) => i)
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', '#fff')
      .attr('stroke-width', 3)
      .attr('d', pathMonth)

    month
      .append('text')
      .attr('fill', 'white')
      .attr(
        'x',
        (d) => timeWeek.count(d3.utcYear(d), timeWeek.ceil(d)) * cellSize + 2
      )
      .attr('y', -5)
      .text(formatMonth)
  }, [props.data, props.height, props.width])

  return (
    <div
      id='chartContainer'
      className={classes.chartContainer}
      ref={containerRef}
    >
      <div id='CalendarHeatmap'>
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            id={'d3Chart'}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
        <button className={classes.tooltip} ref={tooltipRef}></button>
      </div>
    </div>
  )
}

export default CalendarHeatmap
